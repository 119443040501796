/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// SCREEN WIDTH 1450px - START

@media screen and (min-width: 1450px)
	div.DivTableContainer
		ul.ListTableConversation
			li
				div.DivConversationGeneral
					div.DivConversationHeader
						div.DivConversationSubHeader
							span.SpanConversationTitle
								max-width: initial !important
div#divMainDashboard
	div#divBodyFooterContainer
		div.DivFooterVerticalContainer
			width: 400px
	div.DivRadioButtonProduct
		display: flex

// SCREEN WIDTH 1450px - END


// SCREEN WIDTH 1424px - START

@media screen and (max-width: 1424px)
	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationHeader
					div.DivConversationProfile
						display: none

// SCREEN WIDTH 1424px - END


// SCREEN WIDTH 1330px - START

@media screen and (max-width: 1330px)
	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			flex-direction: column
			div.DivContainer
				margin-top: $SPACE_SMALL
				margin-right: 0px
				margin-left: 0px
				margin-bottom: $SPACE_SMALL
			div.DivFormVerticalContainer
				flex-direction: row
				justify-content: flex-start

// SCREEN WIDTH 1330px - END


// SCREEN WIDTH 1250px - START

@media screen and (max-width: 1250px)
	div#divBodyDashboard1
		div.DivContentDashboard
			div.DivContentSection
				div.DivContentTitle
					h3
						font-size: 3em


// SCREEN WIDTH 1250px - END


/* SCREEN WIDTH 1140px - START */

@media screen and (max-width: 1140px)
	div#divBodyHome main
		padding: $SPACE_SMALL

	div.DivVerifierGeneral, div.CenterGeneral
		margin: $SPACE_SMALL

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader, input[type=button]#buttonNavigation
		top: $SPACE_SMALL

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader
		right: $SPACE_SMALL

	input[type=button]#buttonNavigation
		left: $SPACE_SMALL

	div.DivMainAsideSearch
		flex-direction: column
		form
			width: 100%
		div.DivDynamicContainer
			margin-left: 0px
			div.DivSubDynamicContainer
				margin-top: 0px

	div.DivContainerListHorizontal
		div.DivListContainer
			form
				div.DivForm
					fieldset
						div.DivFormListHorizontalContainer
							padding-left: $SPACE_LITTLE
							flex-direction: column
							div.DivForm2Column
								width: 100%
							div.DivRoundButtonContainer
								display: none
				input[type=button]#inputButtonSubmit
					display: block

	div.DivForm
		padding-left: $SPACE_SMALL
		padding-right: $SPACE_SMALL

	div.DivTableContainer
		margin-left: 0px

	+generateKeyFrames(animationNavigationShow)
		0%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)
		100%
			margin-left: 100px
			+setOpacity(1.0)

	+generateKeyFrames(animationNavigationHide)
		0%
			margin-left: 100px
			+setOpacity(1.0)
		100%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)

	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationHeader
					div.DivConversationProfile
						display: block

	div#divMainDashboard
		section
			div.DivFormHorizontalContainer
				div.DivForm2Column:last-of-type
					margin-left: 0px

// SCREEN WIDTH 1140px - END


// SCREEN WIDTH 950px - START

@media screen and (max-width: 950px)
	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			div.DivFormVerticalContainer, div.DivFormHorizontalContainer
				flex-direction: column
				align-content: center
				align-items: center

	form div.DivForm fieldset, div.DivForm
		div.DivFormVerticalContainer
			flex-direction: column
			div.DivForm2Column
				margin-left: 0px
				margin-right: 0px

	form.CalculateForm
		width: 100%
		div.DivForm
			fieldset
				div.DivFormHorizontalContainer
					div.DivForm2Column
						width: 100%
		input[type=button].ButtonRight
			width: 100%

	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationStatus
					flex-direction: column
					flex-shrink: 1
				div.DivConversationHeader
					flex-direction: column
					align-items: flex-end
					div.DivConversationProfile
						display: none
			table.TableListDetails
				tbody
					tr
						td
							display: table-cell
						td:nth-child(2), td:nth-child(3)
							width: auto
						td:nth-child(3)
							display: none
	div#divMainDashboard div.DivSiteContainer
		margin: $SPACE_BIG
	div#divMainDashboard
		section.SectionSecondary
			div.DivSiteContainer
				div#divProductWithDetail, div#divProductWithDetailReverse
					img.ImageFormDetail
						width: 300px
						height: 300px
					img.ImageDownload
						width: 140px
	div#divMainProductContainer
		section.SectionSecondary, section#sectionHeaderProduct
			height: auto
			div.DivFormVerticalContainer
				flex-direction: column
				div
					width: auto !important
				div.DivProduct
					display: flex
					margin: auto
					h2
						margin: auto $SPACE_LITTLE

// SCREEN WIDTH 950px - END


// SCREEN WIDTH 800px - START

@media screen and (max-width: 800px)
	div.DivBodyGeneral
		scroll-snap-align: unset
		position: relative
	div#divParagraphLanding, div#divProductLanding
		width: 100% !important
		margin-top: 50px !important
		h3
			display: inline

	div#divMainDashboard,
		div.DivModal
			div.DivModalHeader
				h2
					font-size: 1.3em
			div.DivModalFigureContainer
				height: 100%
				margin: 10px
				figure
					width: auto
					height: auto
				iframe
					height: 220px

	div#divBodyDashboard1
		div.DivContentDashboard
			div.DivContentSection
				margin-top: 100px
				margin-bottom: 20px
				margin-left: 20px
				margin-right: 20px
				div.DivContentTitle
					h3
						font-size: 2em

	div#divBodyDashboardBussinesPillars
		div.DivContentLayoutContent
			div.DivContentCorporate
				margin: 100px 20px 20px 20px
				div.DivContentCorporateTitle
					h1
						text-align: center
			div.DivContentCorporateDescription
				margin: 0px
				p
					font-size: 12pt
		div.DivContentLayoutSide
			display: inline-flex
			justify-content: center
			flex-direction: column
			span
				margin: 2px 0
			span.active
				width: 12px
				height: 45px
		div.DivContentCorporateDescription
			margin: 0px 50px
			max-width: 100%
			margin-bottom: 50px

	div#divBodyDashboardAward
		div.DivContentAwardsTitle
			margin: 100px 0px 0px 0px
			height: 50px
		div.DivContentAwardsContent
			flex-direction: column
			align-items: center
			justify-content: space-between
			hr
				display: none
				width: 0px
			div.DivVerticalLine
				display: block
				border-left: 6px solid  green
				position: absolute
			div.DivContentCalenderDetail
				top: 0px
				margin-top: 150px
				width: -webkit-fill-available

	div#divBodyDashboardBRINSUpdate
		div.DivContentCorporateSlider
			margin-top: 20px
			margin-bottom: 20px
			margin-left: 20px
			margin-right: 20px
			display: flex
			flex-direction: column
			justify-content: space-between
			div.DivContentCorporateTitle
				display: flex
				flex-direction: column
				height: 100%
				align-items: center
				div.DivContentTextDetail
					width: 100%
					p
						text-align: center
			div.DivContentImageCorousel
				margin-top: 20px
				padding: 0px
				section#sectionImageCorousel
					width: 30vh
					height: 30vh
			div.DivContentDescription
				display: flex
				flex-direction: column
				padding: 0px
				h1
					text-align: center
					font-size: 1.2em
				p
					font-size: 1em

	div#divBodyDashboardAboutBRINS
		div.DivContent
			margin-top: 100px
			margin-bottom: 20px
			margin-left: 20px
			margin-right: 20px
			div.DivContentTitle
				display: flex
				flex-direction: column
				margin-bottom: 0px
				hr
					margin: 20px 0px 20px 0px
					height: 0px
					background: #FFFFFF
			div.DivContentDescription
				height: unset
				padding-bottom: 20px
			div.DivContentButtonDetail
				text-align: left
				margin-top: 20px
				margin-left: 0px
		h1
			font-size: $FONT_LABEL_H3
			font-weight: 600
			margin-right: 0px
			margin-left: 0px
			letter-spacing: 5px
			width: 100%
		hr
			width: 100%
		p
			font-size: 1.75em
			text-align: justify-all
			margin-left: 0px
			height: 100%

	div#divBodyDashboardPartners
		div.DivContentPartners
			margin-top: 50px
			margin-left: 20px
			margin-right: 20px
			margin-bottom: 20px
			height: 100%
			div.DivContent2Column
				display: flex
				margin: 20px
				height: 100%
				align-items: center
				flex-direction: column
				div.DivContent
					margin: 0px
					h2
						text-align: center
					p
						text-align: left
					img
						display: none
			div.DivContent:nth-child(odd)
				width: 100%
			div.DivContent:nth-child(even)
				width: 100%
			div.DivContentShapeDescription
				display: none
			img#imageIconPartners
				display: none
		div.DivContent
			margin-top: 0px
			margin-left: 20px
			margin-right: 20px
			margin-bottom: 0px
			h1
				margin-top: 0px
			div.DivContentImageGallery
				div.DivButtonNavigate
					img
						width: 10px
						height: 10px
						padding: 15px
				section#sectionImageGallery
					label.labelImagePrevious, label.labelImageNext
						display: none
					label.labelImageActive
						img
							width: 200px

	// ABOUT - START

	div#divBodyAbout
		margin-bottom: 0px
		div.DivContentMain
			display: flex
			height: 100vh
			width: auto
			margin-bottom: 0px
			flex-direction: column
			div.DivContentAbout
				display: flex
				flex-direction: column
				margin-top: 100px
				margin-right: 20px
				margin-left: 20px
				margin-bottom: 20px
				width: auto
				height: 100%
				div.DivHeaderAbout, div.DivAboutHeaderTitle
					text-align: center
			div.DivContentDescription
				width: 100%
				margin-top: 0px
				margin-bottom: 0px
				p
					text-align: center
			div.DivContentButtonAbout
				div.DivContentButtonDetail
					a.ButtonForward
						left: 0px
			h1
				font-size: 2.5em
			p
				font-size: 1.2em
				font-weight: 550
		div.DivContentImage
			display: flex
			position: relative
			width: 100%
			height: 100%
			flex-direction: column
			align-items: center
			img:nth-of-type(1)
				position: inherit
				left: 0px
				top: 0px
				height: 250px
				width: 300px
			img:nth-of-type(2), img:nth-of-type(3), img:nth-of-type(4), img:nth-of-type(5)
				width: 40px
				height: 40px
			img:nth-of-type(2)
				position: absolute
				left: 27px
				top: 186px
			img:nth-of-type(3)
				position: absolute
				right: 26px
				top: 175px
			img:nth-of-type(4)
				position: absolute
				right: 28px
				top: 30px
			img:nth-of-type(5)
				position: absolute
				left: 30px
				top: 30px
		div#divContentWhatBRINS
			display: flex
			flex-direction: column
			height: 100vh
			div.DivContentAbout
				display: flex
				margin-top: 20px
				margin-right: 20px
				margin-left: 20px
				margin-bottom: 20px
				height: 100%
				flex-direction: column
				p.DivContentSectionInactive
					display: none
				p.DivContentSectionActive
					display: block
				p
					height: 100%
					font-size: 15pt
					text-align: center
				h1
					font-size: 2.8em
		div#divBodyMissionAndVission
			height: 100vh
			div.DivContentColumn
				display: flex
				flex-direction: column
				margin: 20px
				background-color: red
				align-items: center
				div.DivContentImage
					img
						width: 35vh
						object-fit: contain
					img.ImageShapeCEO
						position: absolute
						z-index: -1
						width: 100%
						height: 100%

	// ABOUT - END


	// START PRODUCT INSURTECH

	div#divMainProductContainer, div#divMainSectionContainer
		section#sectionHeaderProduct, section#sectionPackageProduct
			height: calc((100vh/3)*2)
			background-position-x: 60%
			img.ImgShapeMain1
				width: 100%
				margin-left: -24%
			img.ImgShapeMain2
				height: 25%
			input:disabled
				-webkit-text-fill-color: #7d7d7d
				opacity: 1
			div.DivFormVerticalContainer
				justify-content: center
				margin-bottom: -15%
				div.DivProductHeaderContainer
					display: block
					width: 75% !important
					background-color: rgb(109 110 113 / 46%)
					padding: 20px
					border-radius: 25px
					margin-bottom: 20px
				div.DivProduct
					flex-direction: column
					align-self: flex-start
					margin: 0
					img
						width: 100px
						height: 100px
						margin: 0
					h2
						font-size: 2em
						line-height: 60px
						margin: 0
				div.DivProductDetail
					margin: 0
					h3
						width: 65%
						font-size: 1.2em
						margin-top: 20px
						margin-bottom: 20px
					p
						width: 65%
						line-height: 20px
						font-size: 1em
						line-height: 30px
			div#divFormVerticalContainerContent
				height: auto
			div.DivSiteContainer
				form
					div.DivForm
						padding: 20px
						fieldset
							margin-top: 0
							div.DivFormHorizontalContainer
								div.DivForm2Column
									margin-bottom: 10px
									span#numberModelProductGeneralPremiumTotalAmount, span#spanPaymentVirtualAccountNumber, span#spanPaymentTotal
										font-size: 1.7em
				div.DivFormHorizontalContainer
					div.DivForm2Column
						p
							line-height: 30px
						div.DivProductPackage
							flex-wrap: nowrap
							justify-content: space-evenly
							div.DivContentBox
								width: 150px
								height: 130px
								margin-top: 20px
								img
									width: 100px
									height: 100px
									margin-bottom: 30px
		section#sectionPackageProduct
			height: 100%
			div.DivFormVerticalContainer
				height: auto
				margin-bottom: 20px
				h2
					margin-top: 20px
					font-size: 2em
			div.DivFormHorizontalContainer
				flex-direction: column
				div.DivForm2Column
					width: 100%
					h2
						font-size: 2em
					div.DivProductPackage
						justify-content: space-evenly
						div.DivContentBox
							margin-left: 0
					button
						width: 100%
			div.DivSiteContainer
				margin-top: 40px
				div.DivFormHorizontalContainer
					flex-direction: column
					div.DivForm2Column
						width: 100%
						margin-top: 0
						form
							margin-top: 0
				div.DivContentBox
					margin-top: 20px
				div.DivRadioButtonContainer
					flex-direction: column
					margin: 0
					div.DivRadioButtonProduct
						display: flex
						margin-top: 10px
						margin-bottom: 5px
					div.DivRadioButton
						height: 18px
						width: 18px !important
						margin: auto
						margin-right: 10px
						margin-left: 0
					label
						margin: auto 0
				.mat-checkbox
					.mat-checkbox-label
						p
							line-height: 25px
				div.DivInputHorizontalContainer
					input[type=number]
						min-width: 0
				div.DivCheckBoxContainer
					width: 100%
				div.DivDownloadImage
					width: 100% !important
					padding-right: 15px
					label
						max-width: 400px
						overflow: hidden
						text-overflow: ellipsis
					input[type=button]
						padding-left: 30px
						padding-top: 0px
						background-position-x: initial
						box-sizing: border-box
						border-radius: 0px
						font-size: 1.092em !important
		section#sectionCheckoutContainer
			height: 100%
			h3
				width: 100%
				margin-top: 20px
			form
				margin-top: 10px
				margin-bottom: 0
				div.DivForm, PaddingLR0
					padding-top: 10px
					padding-bottom: 10px
			div.DivSiteContainer
				margin-top: 40px
				margin-bottom: 40px
			div.DivForm2Column, WidthAuto
				width: 100% !important
				margin: auto
				margin-bottom: 15px
				padding: 0
				label, span
					font-size: 1.2em
				div.DivCheckoutContainer
					margin-top: 20px
				span#spanModelProductGeneralPremiumTotalAmount, span#spanPaymentVirtualAccountNumber, span#spanPaymentTotal
					font-size: 1.7em !important
				input[type=button]
					font-size: 1.2em
				div.DivListContentLong, div.DivListContentLongActive
					p
						font-size: 1.2em
			div.PaddingLeftBorder
				border: 0
				margin-top: 5px

	// END PRODUCT INSURTECH

	// FOOTER - START

	div#divMainLanding, div#divMainDashboard
		div#divBodyFooterContainer
			height: 100%
			scroll-snap-align: unset
			div.DivFooterInfoPage
				display: flex
				flex-direction: column
				justify-content: unset
				padding-top: 20px
				padding-left: 20px
				padding-right: 20px
				padding-bottom: 100px
				align-items: center
				div.DivFooterVerticalContainer
					width: 100%
					display: flex
					flex-direction: column
					align-items: center
					label
						display: block
						text-align: center
					p
						text-align: center
					div.DivFooterContentContainer
						div.DivFooterSVGContainer
							display: none
					div.DivFooterHorizontalContainer
						display: flex
						justify-content: center
						&:first-of-type
							margin-top: -5px
						div.DivFooterSVGLargeContainer, div.DivFooterSVGMediumContainer
							img
								margin-top: 0px !important
					div.DivFooterSocialMedia
						margin-top: 0px
						margin-bottom: 40px
						div.DivFooterHorizontalContainer
							justify-content: flex-start
							margin: auto
							div.DivFooterSVGContainer
								width: 60px
								img
									display: block
									width: 30px
									height: 30px
									margin: 10px 20px
				div#divSectionHQContactUs
					display: grid
					grid-template-columns: repeat(2, 1fr)
					text-align: center
					align-items: start
					div.DivFooterHQLocation
						div.DivFooterContentContainer
							margin: 0
					div.DivFooterContactUs
						a.AFooterSVGContainer
							img
								margin: 20px auto !important
				div.DivFooterPageHorizontalContainer
					width: 100%
					div.DivFooterSectionPage
						display: flex
						flex-direction: column
						div.DivFooterLink
							div.DivFooterContentContainer
								width: 100%
								div.DivParagraphLink
									display: grid
									grid-template-columns: repeat(2, 1fr)
									width: 100%
						div#divFooterSubscribeContainer
							width: 100%
							margin-bottom: 10px
							margin-top: 10px
							div.DivFooterVerticalContainer
								width: auto
								div.DivFormSubscribeEmail
									width: 100%
									font-size: 90%
			div.DivFooterCopyright
				display: none
			div.DivFooterCopyrightResponsive
				display: flex
			div.DivFormSubscribeEmail
				margin-top: 15px !important
				margin-bottom: 15px !important

	// FOOTER - END

// SCREEN WIDTH 800px - END


// SCREEN WIDTH 800px - BRINS WEBSITE - START

@media screen and (max-width: 800px)
	body
		font-size: 90%
		div#divCurtainLoading
			div#divLoadingMessage
				img#imageLoadingCircle
					margin: 0
		section
			div.DivFormHorizontalContainer
				flex-direction: column
				align-content: center
				align-items: center
			div.DivFormHorizontalContainer
				flex-direction: column
				div.DivForm2Column
					margin-left: 0px
					margin-right: 0px
					width: 100%
				div.DivForm2ColumnNoMargin:last-of-type:nth-of-type(odd)
					div.DivImageCapture
						width: 100%
	div#divMainDashboard
		section.SectionSecondary
			div#divSitePromotionList
				div.DivPromotionHorizontal
					div.DivImagePromotion
						img.ImageLabel
							width: 400px
				div.DivPromotion
					img.ImageLabel
						width: 400px
						margin: 10px auto
				div.DivPromotionDetailHorizontal
					flex-direction: column
					div.DivImagePromotion:nth-of-type(1)
						img.ImageLabel
							width: 100%
					div.DivDetailPromotion
						margin-top: 20px
			div.DivSiteContainer
				form
					div.DivForm
						padding: 10px
						div.DivPaymentMethod
							img.ImagePaymentMethod
								margin: auto
				div#divProductWithDetail, div#divProductWithDetailReverse
					div.DivFormHorizontalContainer
						img.ImageDownload
							width: 100px
					h2
						margin-bottom: 0px
					p
						text-align: justify
					img.ImageFormDetail
						margin-left: auto !important
						margin-right: auto !important
						width: 400px
						height: 400px
					div.DivFormHorizontalContainer
						flex-direction: row
						img.ImageDownload
							margin-left: auto
							margin-right: auto
					div.DivForm2Column:nth-child(1)
						height: auto
						margin-bottom: 30px !important
					div.DivForm2Column:nth-child(2)
						height: max-content
						margin-bottom: 50px
			div.DivFormHorizontalContainer, div.DivFormVerticalContainer
				button.ButtonMore
					padding: 10px 30px
		div#divMainLandingContainer
			section.SectionMain
				position: relative
				background-position-x: center
				height: 100vh
				// height: 44.3vh
				background-position-x: 60%
				img.ImgShapeMain1
					width: 125%
					margin-left: -30%
				img.ImgShapeMain2
					height: 15%
				div#divMainLanding
					flex-wrap: nowrap
					flex-direction: column
					margin-left: 0px
					margin-right: 0px
					div.DivHeaderCenterContainer
						margin-top: 30%
						justify-content: flex-end
						height: max-content
						flex-grow: 3
						flex-shrink: 1
						flex-basis: auto
						div.DivHeaderContentContainer
							box-sizing: border-box
							padding: 10px 15px
							border-radius: 25px
							width: 80%
						h1
							margin: 0
							line-height: $FONT_PARAGRAPH_LINE_HEIGHT_ENORMOUS
							font-size: 3em
						p
							width: 90% !important
							margin: 0
							margin-top: 20px
					div.DivSocialMediaCenterContainer
						height: 15px
						flex-grow: 1
						flex-shrink: 2
						flex-basis: auto
						div.DivSocialMedia
							padding: 10px 15px
							flex-direction: row
							width: 60%
							align-items: flex-start
							margin-left: 0
							img
								width: 25px
								height: 25px
								&:first-of-type
									margin-left: 0
				div.DivFormHorizontalContainer
					div.DivForm2Column
						width: 100%
			section.SectionSecondary
				div#divSiteLandingContainer
					flex-direction: row
					margin: 40px
					margin-bottom: 30px
					div.DivFirstParagraph
						flex-direction: column-reverse
						div.DivFirstParagraphText
							width: 100%
						div.DivFirstParagraphImage
							width: 100%
							margin-bottom: 20px
							img
								width: 100%
					div.DivHeaderProductLanding
						flex-direction: column-reverse
						height: 100%
						border-bottom-left-radius: 15px
						border-bottom-right-radius: 15px
						div.DivHeaderProductDescription
							padding: 30px
							width: 100%
							height: 270px
							div.DivProductContent
								flex-wrap: nowrap
						div.DivHeaderProductImageDescription
							width: 100%
							height: 300px
							border-top-left-radius: 15px
							border-top-right-radius: 15px
					div.DivFormHorizontalContainer
						flex-direction: column
						div.DivProductDescription
							height: 450px
							padding: 30px
						div.DivProductLanding, div.DivFooterProductLanding
							width: 100%
							border-radius: 15px
							div.DivProductImageDescription
								width: 50%
								border-top-left-radius: 15px
								border-bottom-left-radius: 15px
								img
									width: 50vw
							div.DivProductContent
								flex-wrap: nowrap
						div.DivFooterProductLanding
							margin-bottom: 10px
				div#divProductLanding
					div.DivContentImage
						div.DivFormHorizontalContainer
							flex-direction: row
		div#divMainSectionContainer
			section#sectionHeaderProduct
				height: calc((100vh/3)*2)
				background-position-x: 60%
				img.ImgShapeMain1
					width: 100%
					margin-left: -24%
				img.ImgShapeMain2
					height: 25%
				div.DivSiteContainer
					flex-direction: row
					align-content: flex-end
					div.DivProduct
						width: 100%
						margin-bottom: 15%
						h2
							line-height: 80px
							font-size: 4em
						h3
							font-size: 3em
						h3 > div.DivProduct
							margin: 0
						h2,h3
							text-align: left
							margin: 0
							box-sizing: border-box
							padding: 10px
							border-radius: 15px
							width: 60%
							white-space: pre-wrap
			section.SectionSecondary
				div.DivSiteContainer, div#divBodyTabAbout
					margin: 40px
					h1
						font-size: 1.8em
					h2
						font-size: 1.5em
					h3
						font-size: 1.25em
					div.DivContentImageGallery
						padding: 0
						height: 200px
						div.DivButtonNavigate
							width: 25px
							height: 25px
							img
								width: 10px
								height: 10px
								padding: 10px
								border-radius: 8px
						section#sectionImageGallery
							height: 130px
							label.LabelImageNonActive
								margin-top: 0
								width: 140px
								img
									width: 130px
									margin: 0 auto
								&:first-of-type, &:last-of-type
									display: none
								p
									width: 80px
									font-size: 0.6em
					div#divProductDetailContainer
						flex-direction: column
						div.DivIconProduct
							margin: 50px auto
							width: 200px
							height: 200px
							img
								width: 200px 
								height: 200px
						div.DivDescriptionDetailContainer
							h2
								font-size: 2.5em
					div.DivFormHorizontalContainer
						button.ButtonMore
							padding: 10px 30px
							border-radius: 30px
							border: solid 4px #F37024
							margin-bottom: 40px
				div#divSiteProductList
					grid-template-columns: repeat(2, 1fr)
					grid-gap: 20px
					div#divContentProductList
						height: 360px !important
						font-size: 90%
						flex-basis: 0
						padding: 20px
						margin: 0
						margin-bottom: 20px
						div.DivTitleContentHeader
							img
								margin: 10px
								margin-left: 5px
							label
								font-size: 1em
						p
							line-height: 24px
							margin: 0
							margin-top: 10px
						button.ButtonSubmit
							font-size: 0.8em
							height: 32px
							left: 25px
							right: 25px
							padding: 5px 10px
							border-radius: 10px
				div#divBodyTabAbout
					margin: 50px 50px 30px 50px
					div#divContentPolicy
						div.DivPolicyList
							margin: 0
							margin-bottom: 20px
							width: 100%
							padding: 20px
							div.DivPolicyContentImage
								width: 100px
								height: 100px
							div.DivLabelPolicyProductTitle
								margin: 0 20px 0 20px
								label
									font-size: 1.8em
							div.DivPolicyContentInformation
								label
									font-size: 1.5em
		section#sectionMobileApplication
			div#divContentCart
				div.DivCartList
					margin-bottom: 20px
					div.DivCartOrderDetail
						margin: 0px 15px
						width: 600px
						label, h3
							margin-bottom: 0px
							font-size: 1.6em
						p, h5
							margin-bottom: 0px
							line-height: 25px
						h5
							font-size: 1.6em
					div.DivCartOrderProductList
						img
							height: 70px
							width: 70px
						h3, p
							line-height: 25px
							font-size: 100%
					div.DivPaymentMethod
						display: flex
						img.ImageLogoPayment
							height: auto
						div.DivProductProgressContainer
							width: 100px
							height: 100px
							.mat-progress-spinner
								width: 100px !important
								height: 100px !important
								svg
									width: 100px !important
									height: 100px !important
							h2
								font-size: 1.5em

// SCREEN WIDTH 800px - BRINS WEBSITE - END


// SCREEN WIDTH 720 - START

@media screen and (max-width: 720px)
	div#divMainDashboard
		font-size: 85%
		ol.OrderedNumber
					margin: 0px 15px
					li
						p
							line-height: 20px
		section#sectionMobileApplication
			div#divContentCart
				div.DivCartList
					div.DivCartOrderDetail
						width: 500px
					div.DivCartOrderProductList
						img
							height: 50px
							width: 50px
		div#divMainSectionContainer
			section.SectionSecondary
				div#divBodyTabAbout
					margin: 30px


// SCREEN WIDTH 720 - END


// SCREEN WIDTH 640px - START

@media screen and (min-width: 641px)
	div#divNotificationInstall
		width: 420px
		height: auto
		right: auto
		position: fixed
		left: $SPACE_MEDIUM
		bottom: $SPACE_MEDIUM
		+setBorderRadius($RADIUS_INPUT_GENERAL)

@media screen and (max-width: 640px)

	div.DivForm, form div.DivForm fieldset
		.mat-form-field
			width: 100%

	div.DivDynamicContainer
		div.DivConversationHeader
			div.DivConversationProfile
				display: none

	div.DivTableContainer
		ul.ListTableTicket
			img
				display: none
			div.DivTicketItem
				div.DivTicketSubItem:last-of-type
					display: flex
					flex-direction: column
				div.DivTicketTail
					display: flex
					flex-shrink: 1
			li
				padding-left: $SPACE_SMALL
				padding-right: $SPACE_SMALL
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				justify-content: center
				width: 100%
		div.DivTableControl
			flex-direction: column
			div.DivTableSubControl
				margin-top: $SPACE_TINY
				margin-bottom: $SPACE_TINY
		table
			margin-top: $SPACE_SMALL
			margin-bottom: $SPACE_SMALL
			thead
				tr
					th
						padding: $SPACE_THIN
						display: block
					th:first-of-type
						padding-left: 0px
						+setBorderRadiusTop($RADIUS_TABLE_GENERAL)
						+setBorderRadiusBottom(0px)
					th:last-of-type
						padding-right: 0px
						+setBorderRadiusTop(0px)
						+setBorderRadiusBottom($RADIUS_TABLE_GENERAL)
				tr:first-of-type
					th
						padding-top: $SPACE_TINY
				tr:last-of-type
					th
						padding-bottom: $SPACE_TINY
			tbody, tfoot
				tr
					td
						padding: $SPACE_THIN
						display: block
						+setBorderRadius(0px)
					td:first-of-type
						padding-top: $SPACE_TINY
						padding-left: 0px
					td:last-of-type
						padding-right: 0px
						padding-bottom: $SPACE_TINY
				tr:first-of-type
					td:first-of-type
						+setBorderRadiusTop($RADIUS_TABLE_GENERAL)
					td:last-of-type
						+setBorderRadiusTop(0px)
				tr:last-of-type
					td:first-of-type
						+setBorderRadiusTop(0px)
						+setBorderRadiusBottom(0px)
					td:last-of-type
						+setBorderRadiusBottom($RADIUS_TABLE_GENERAL)
			th, td
				text-align: center

	main.MainShow
		+setSlideTransform(0, 0px, 0px, 1.0, 1.0, 1.0)
		+setTransition(all, 0.5, ease-in)
	main.MainHide
		+setSlideTransform(-60, 180px, -180px, 0.6, 0.6, 0.6)
		+setTransition(all, 0.5, ease-out)

	// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

	#divMainDashboard
		div.DivFormCardInside
			position: relative 
			margin: $SPACE_MEDIUM
			width: 100%
			z-index: 1
			margin-left: 0px
			div.DivFormBranch
				margin-bottom: $SPACE_BIG
				div.DivBranchCardInformation
					img
						border-radius: 50%
						padding: $SPACE_TINY
				input[type=button].ButtonShare
					background-position-x: 170px
				p
					margin-right: 200px
		div.DivFormNoBorder
			google-map
				.map-container
					border-radius: $SPACE_SMALL


	// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

	div#divMainDashboard
		font-size: 80%
		div.DivSiteContainer
			margin-left: 20px !important
			margin-right: 20px !important
			div.DivForm
				div.DivForm2Column
					margin-top: 0px
					margin-bottom: 0px
		div#divProductContainer
			div.DivFormHorizontalContainer
				flex-direction: row
		div#divMainLanding
			flex-direction: row
			margin-right: 0px
			margin-left: 0px
			h1
				margin-bottom: 0 !important
		section.SectionSecondary
			h1
				margin-bottom: 10px !important
				font-size: 2.25em
			div#divBodyTabAbout
				margin: 20px
			div#divSitePromotionList
				div.DivPromotionHorizontal
					div.DivImagePromotion
						img.ImageLabel
							width: 300px
				div.DivPromotion
					img.ImageLabel
						width: 300px
						margin: 10px auto
			div.DivSiteContainer
				div#divProductWithDetail, div#divProductWithDetailReverse
					img.ImageFormDetail
						width: 100%
						height: 100%
		section#sectionProductListContainer
			div#divSiteProductList
				div#divContentProductList
					margin: 25px 0px
		div.DivModal
			width: 90%
		div#divMainSectionContainer
			section.SectionSecondary
				div#divBodyTabAbout
					margin: 20px
				div#divBodyTabAbout
					div#divContentPolicy
						div.DivPolicyList
							margin: 0
							margin-bottom: 20px
							width: 100%
							padding: 15px
							div.DivPolicyContentImage
								width: 80px
								height: 80px
								margin-right: 0
								img
									width: 80px
									height: 80px
							div.DivLabelPolicyProductTitle
								margin: 0 10px
								min-width: 120px
								label
									font-size: 1.5em
	div#divMainLandingContainer
		div#divMainLanding
			div.DivHeaderCenterContainer, div.DivSocialMediaCenterContainer
				p
					width: 300px !important
				h1
					font-size: 3em
				h2
					font-size: 2em
		div#divSiteLandingContainer
			div.DivContentBox
				width: 350px
				margin-top: 30px
		div#divParagraphLanding
			p
				text-align: justify
				line-height: 21px
			h3
				display: inline
		div#divProductContainer
			margin-left: 0
			margin-right: 0
			width: 100% !important
			div.DivFormHorizontalContainer
				div.DivProduct
					img
						width: 60px
						height: 60px
					label
						font-size: 0.8em !important
	div#divMainSectionContainer
		section#sectionHeaderProduct
			div.DivProductTitle
				margin: auto 0 90px 0 !important
				width: 80% !important
				h2
					font-size: 3.5em !important
	div.DivSignInContainer
		flex-direction: column !important
		div.DivSignInBackground
			border-top-right-radius: 0px !important
			border-bottom-left-radius: 50px
			height: 150px !important
			svg
				width: 150px
		div.DivSignInFormContainer
			flex-direction: row !important
			padding: 0px 20px !important
			header
				margin-bottom: 20px !important
	div#divBodyHomeMenu
		nav#navigationMainHome
			top: 10px
			left: 10px
			right: 10px
			img#imageLogoHeader
				height: 25px
				margin: auto 15px

	// START PRODUCT INSURTECH

	div#divMainProductContainer, div#divMainSectionContainer
		section#sectionHeaderProduct, section#sectionPackageProduct
			height: calc((100vh/3)*2)
			background-position-x: 60%
			img.ImgShapeMain1
				width: 100%
				margin-left: -14%
			img.ImgShapeMain2
				height: 25%
			div.DivFormVerticalContainer
				justify-content: center
				div.DivProductHeaderContainer
					display: block
					width: 75% !important
					background-color: rgb(109 110 113 / 46%)
					padding: 20px
					border-radius: 25px
					margin-bottom: 20px
				div.DivProduct
					flex-direction: column
					align-self: flex-start
					margin: 0
					img
						width: 100px
						height: 100px
						margin: 0
					h2
						font-size: 1.7em
						line-height: 40px
						margin: 0
				div.DivProductDetail
					margin: 0
					h3
						width: 60%
						font-size: 1.5em
						margin-top: 0
						margin-bottom: 0
					p
						width: 60%
						line-height: 20px
						font-size: 1.5em
			div.DivSiteContainer
				margin-top: 80px
				form
					div.DivForm
						padding: 20px
						fieldset
							margin-top: 0
							div.DivFormHorizontalContainer
								div.DivForm2Column
									margin-bottom: 0px
									span#numberModelProductGeneralPremiumTotalAmount, span#spanPaymentVirtualAccountNumber, span#spanPaymentTotal
										font-size: 1.7em
				div.DivFormHorizontalContainer
					div.DivForm2Column
						p
							line-height: 30px
						div.DivProductPackage
							flex-wrap: nowrap
							justify-content: space-evenly
							div.DivContentBox
								width: 130px
								height: 130px
								margin-top: 20px
								img
									width: 100px
									height: 100px
									margin-bottom: 30px
		section#sectionPackageProduct
			height: 100%
			div.DivFormVerticalContainer
				height: auto
				h2
					margin-top: 20px
					font-size: 2em
			div.DivFormHorizontalContainer
				div.DivForm2Column
					h2
						font-size: 2em
					div.DivProductPackage
						justify-content: space-evenly
						div.DivContentBox
							margin-left: 0
					button
						width: 100%
			div.DivSiteContainer
				margin-top: 40px
				div.DivForm2Column
					margin-top: 0
					form
						margin-top: 0
				div.DivContentBox
					margin-top: 30px
				div.DivRadioButtonContainer
					flex-direction: column
					margin: 0
					div.DivRadioButtonProduct
						display: flex
						margin-top: 10px
						margin-bottom: 5px
					div.DivRadioButton
						height: 18px
						width: 18px !important
						margin: auto
						margin-right: 10px
						margin-left: 0
					label
						margin: auto 0
				.mat-checkbox
					.mat-checkbox-label
						p
							line-height: 25px
				div.DivInputHorizontalContainer
					input[type=number]
						min-width: 0
				div.DivCheckBoxContainer
					width: 100%
				div.DivDownloadImage
					width: 100% !important
					padding-right: 15px
					label
						max-width: 100px
						overflow: hidden
						text-overflow: ellipsis
					input[type=button]
						width: 100px
						padding-left: 30px
						padding-top: 0px
						background-position-x: initial
						box-sizing: border-box
						border-radius: 0px
						font-size: 1.092em !important
		section#sectionCheckoutContainer
			height: 100%
			h3
				width: 100%
				margin-top: 20px
			form
				margin-top: 10px
				margin-bottom: 0
				div.DivForm, PaddingLR0
					padding-top: 10px
					padding-bottom: 10px
			div.DivSiteContainer
				margin-top: 40px
				margin-bottom: 40px
			div.DivForm2Column, WidthAuto
				width: 100% !important
				margin: auto
				margin-bottom: 15px
				padding: 0
				label, span
					font-size: 1.2em
				div.DivCheckoutContainer
					margin-top: 20px
				span#spanModelProductGeneralPremiumTotalAmount, span#spanPaymentVirtualAccountNumber, span#spanPaymentTotal
					font-size: 1.7em !important
				input[type=button]
					font-size: 1.2em
				div.DivListContentLong, div.DivListContentLongActive
					p
						font-size: 1.2em

	// END PRODUCT INSURTECH

// SCREEN WIDTH 640px - END


// SCREEN WIDTH 490px - START

@media screen and (max-width: 490px)
	ul.ListTableConversation
		li.LiConversationContainerRight, li.LiConversationContainerLeft
			div.DivConversationGeneral
				div.DivConversationItem
					flex-direction: column
					align-items: flex-start
					div.DivConversationHeader
						margin-top: $SPACE_LITTLE
						div.DivConversationSubHeader
							span
								text-align: left !important
							span.SpanConversationTitle
								max-width: initial !important
	div.DivSiteContainer
		margin: 0px 20px !important

	div#divMainDashboard
		div#divMainLandingContainer
			div#divSiteLandingContainer
				div.DivContentBox
					width: 320px
					height: 320px !important
				div#divProductContainer
					min-height: 250px
					height: auto !important
					div.DivFormHorizontalContainer
						div.DivProduct
							img
								width: 55px
								height: 55px
		div#divParagraphLanding
			margin-top: 50px
		div#divProductLanding
			margin-top: 20px
			margin-bottom: 35px
		section#sectionMobileApplication
			div#divContentCart
				div.DivCartList
					margin-bottom: 15px
					div.DivCartOrderDetail
						margin: 0px 10px
						width: 300px
						label, h3
							margin-bottom: 0px
						h3
							font-size: 1.3em
						p, h5
							margin-top: 0px
							margin-bottom: 0px
							line-height: 20px
						label, p
							font-size: 1em
					div.DivCartOrderProductList
						width: 200px
						img
							height: 35px
							width: 35px
						h3, p
							line-height: 15px
							font-size: 75%
					div.DivPaymentMethod
						display: flex
						width: 150px
						div.DivProductProgressContainer
							margin-left: 0
							margin-right: 0
							width: 55px
							height: 55px
							.mat-progress-spinner
								width: 55px !important
								height: 55px !important
								svg
									width: 55px !important
									height: 55px !important
							h2
								font-size: 1.25em
		div#divMainSectionContainer
			section.SectionSecondary
				div#divBodyTabAbout
					margin: 10px
				div#divBodyTabAbout
					div#divContentPolicy
						div.DivPolicyList
							font-size: 75%
							padding: 10px
							border-radius: 20px
							div.DivPolicyContentImage
								width: 50px
								height: 50px
								img
									width: 50px
									height: 50px
							div.DivLabelPolicyProductTitle
								margin: 0 10px
								min-width: 130px
								label
									font-size: 1.5em
							div.DivPolicyContentInformation
								p
									line-height: 20px
				div#divSitePromotionList
					div.DivPromotionDetailHorizontal
						margin-bottom: 20px
						h2
							margin-top: 0
							margin-bottom: 0
						p
							margin-top: 0
							margin-bottom: 10px
							line-height: 25px
						div.DivDetailPromotion
							ol
								margin-top: 0
								margin-left: 12px
								margin-right: 10px
								line-height: 25px
								li
									ol
										margin-left: 10px
										margin-right: 10px
										list-style-type: lower-alpha

	// START PRODUCT INSURTECH

	div#divMainProductContainer, div#divMainSectionContainer
		section#sectionHeaderProduct, section#sectionPackageProduct
			height: calc((100vh/3)*2)
			background-position-x: 60%
			img.ImgShapeMain1
				width: 100%
				margin-left: -24%
			img.ImgShapeMain2
				height: 25%
			input:disabled
				opacity: 1
			div.DivFormVerticalContainer
				justify-content: flex-end
				div.DivProductHeaderContainer
					display: block
					width: 75% !important
					background-color: rgb(109 110 113 / 46%)
					padding: 20px
					border-radius: 25px
					margin-bottom: 20px
				div.DivProduct
					flex-direction: column
					align-self: flex-start
					margin: 0
					img
						width: 70px
						height: 70px
						margin: 0
					h2
						font-size: 1.4em
						line-height: 45px
						margin: 0
					h2#h2TitleBicycle
						text-align: left
				div.DivProductDetail
					margin: 0
					padding-bottom: 20px
					h3
						width: 60%
						font-size: 1.15em
						margin-top: 0
						margin-bottom: 0
					p
						width: 60%
						line-height: 15px
						font-size: 1.15em
				div#divProductDetailAsmikRumahku
					h3
						width: 80% !important
					p
						width: 70% !important
				div#divProductDetailDiriTahunan, div#divProductDetailDiriBulanan, div#divProductDetailDiriHarian
					padding-bottom: 35px
			div#divFormVerticalContainerContent
				height: auto
			div.DivSiteContainer
				form
					div.DivForm
						padding: 20px
						fieldset
							margin-top: 0
							div.DivFormHorizontalContainer
								div.DivForm2Column
									margin-bottom: 10px
									span#numberModelProductGeneralPremiumTotalAmount, span#spanPaymentVirtualAccountNumber, span#spanPaymentTotal
										font-size: 1.7em
				div.DivFormHorizontalContainer
					div.DivForm2Column
						p
							line-height: 24px
						div.DivProductPackage
							flex-wrap: nowrap
							justify-content: space-evenly
							div.DivContentBox
								width: 100px
								height: 100px
								img
									width: 70px
									height: 70px
									margin-bottom: 30px
		section#sectionPackageProduct
			height: 100%
			div.DivFormVerticalContainer
				height: auto
				h2
					margin-top: 20px
					font-size: 2em
			div.DivFormHorizontalContainer
				div.DivForm2Column
					h2
						font-size: 2em
						margin-bottom: 0
					p
						margin-top: 0
					div.DivProductPackage
						justify-content: space-evenly
						margin-bottom: 50px
						margin-top: 0
						div.DivContentBox
							margin-left: 0
							flex-basis: auto
							width: 100px
							height: 100px
							img
								width: 70px
								height: 70px
								margin-bottom: 30px
					button
						width: 100%
					.ng-select
						.ng-value-label
							font-size: 0.7em
							color: #b4b4b4
			div.DivSiteContainer
				margin-top: 40px
				div.DivForm2Column
					margin-top: 0
					form
						margin-top: 0
					div.DivRadioButtonMultiple
						label
							max-width: 80%
				div.DivContentBox
					margin-top: 15px
				div.DivRadioButtonContainer
					flex-direction: column
					margin: 0
					div.DivRadioButtonProduct
						display: flex
						margin-top: 10px
						margin-bottom: 5px
					div.DivRadioButton
						height: 18px
						width: 18px !important
						margin: auto
						margin-right: 10px
						margin-left: 0
					label
						margin: auto 0
				.mat-checkbox
					.mat-checkbox-label
						p
							line-height: 25px
				div.DivInputHorizontalContainer
					input
						margin-bottom: 0 !important
					input[type=number]
						min-width: 0
					div.DivFormTextarea
						margin-bottom: 10px
				div.DivCheckBoxContainer
					width: 100%
				div.DivDownloadImage
					padding-right: 15px
					label
						max-width: 100px
						overflow: hidden
						text-overflow: ellipsis
					input[type=button]
						width: 100px
						padding-left: 30px
						padding-top: 0px
						background-position-x: initial
						box-sizing: border-box
						border-radius: 0px
						font-size: 1.092em !important
				div.DivTableContainer
					table.TableListDetailsAttachment
						tr
							display: flex
							td:nth-child(1)
								width: 28%
								border-radius: 0
								padding: 5px
								a
									display: flex
									align-items: center
									justify-content: flex-start
									height: 100%
							td:nth-child(2)
								width: 45%
								border-radius: 0
								padding: 5px
								a
									display: flex
									align-items: center
									justify-content: center
									height: 100%
									overflow: hidden
									text-overflow: ellipsis
							td:nth-child(3)
								width: 20%
								border-radius: 0
		section#sectionCheckoutContainer
			height: 100%
			margin-bottom: 30px
			h3
				width: 100%
				margin-top: 20px
			form
				margin-top: 10px
				margin-bottom: 0
				div.DivForm, PaddingLR0
					padding-top: 10px
					padding-bottom: 10px
			div.DivSiteContainer
				margin-top: 40px
				margin-bottom: 40px
			div.DivForm2Column, WidthAuto
				width: 100% !important
				margin: auto
				margin-bottom: 15px
				padding: 0
				label, span
					font-size: 1em
				div.DivCheckoutContainer
					margin-top: 20px
				span#spanModelProductGeneralPremiumTotalAmount, span#spanPaymentVirtualAccountNumber, span#spanPaymentTotal
					font-size: 1.5em !important
				input[type=button]
					font-size: 1em
				div.DivListContentLong, div.DivListContentLongActive
					p
						font-size: 1em
			div.PaddingLeftBorder
				border: 0
				
	// END PRODUCT INSURTECH

	// FOOTER - START

	div#divBodyFooterContainer
		div.DivFooterInfoPage
			div#divSectionHQContactUs
				grid-template-columns: 1fr !important
				div.DivFooterHQLocation
					div.DivFooterContentContainer
						margin: 20px
			div.DivFooterPageHorizontalContainer
				div.DivFooterSectionPage
					div.DivFooterLink
						div.DivFooterContentContainer
							div.DivParagraphLink
								grid-template-columns: 1fr !important

	// FOOTER - END

	// MODAL - START

	div.DivModal
		height: 400px !important

	// modal - END

// SCREEN WIDTH 490px - END


// SCREEN WIDTH 428px - START

@media screen and (max-width: 428px)
	body
		font-size: 80%
		div#divCurtainLoading
			div#divLoadingMessage
				img#imageLoadingCircle
					margin: 0
	div#divMainDashboard
		div#divMainLandingContainer
			section.SectionMain
				position: relative
				background-position-x: 60%
				height: 100vh
				img.ImgShapeMain1
					width: 125%
					margin-left: -28%
				img.ImgShapeMain2
					height: 10%
				div#divMainLanding
					flex-wrap: nowrap
					flex-direction: column
					margin-left: 20px
					margin-right: 20px
					div.DivHeaderCenterContainer
						margin-top: 90%
						margin-right: 10%
						justify-content: flex-end
						height: max-content
						flex-grow: 3
						flex-shrink: 1
						flex-basis: auto
						div.DivHeaderContentContainer
							font-size: 70%
							// background-color: rgb(109 , 110, 113, 0.8)
							box-sizing: border-box
							padding: 10px 15px
							border-radius: 25px
							width: 80%
						h1
							margin: 0
							line-height: 40px
						p
							margin: 0
							width: 90% !important
							margin-top: 5px
							font-size: 1.2em !important
					div.DivSocialMediaCenterContainer
						height: 30px
						flex-grow: 1
						flex-shrink: 2
						flex-basis: auto
						div.DivSocialMedia
							flex-direction: row
							width: 62%
							align-items: flex-start
							margin-left: 0
							img
								width: 18px
								height: 18px
								&:first-of-type
									margin-left: 0
				div.DivFormHorizontalContainer
					div.DivForm2Column
						width: 100%
			section.SectionSecondary
				div#divSiteLandingContainer
					div.DivFirstParagraph
						flex-direction: column-reverse
						div.DivFirstParagraphText
							width: 100%
						div.DivFirstParagraphImage
							width: 100%
							margin-bottom: 20px
							img
								width: 100%
								border-radius: 20px
					div.DivHeaderProductLanding
						flex-direction: column-reverse
						height: 100%
						border-bottom-left-radius: 15px
						border-bottom-right-radius: 15px
						div.DivHeaderProductDescription
							width: 100%
							height: 350px
							padding: 20px
							div.DivProductContent
								flex-wrap: nowrap
						div.DivHeaderProductImageDescription
							width: 100%
							height: 300px
							border-top-left-radius: 15px
							border-top-right-radius: 15px
		div#divMainSectionContainer
			section#sectionHeaderProduct
				height: calc((100vh/3)*2)
				background-position-x: 60%
				img.ImgShapeMain1
					width: 100%
					margin-left: -24%
				img.ImgShapeMain2
					height: 25%
				div.DivSiteContainer
					flex-direction: row
					align-content: flex-end
					div.DivProduct
						margin: 0 0 20% 0
						width: 100%
						h2
							font-size: 2.8em
							text-align: left
							line-height: 40px
							margin: 0
							box-sizing: border-box
							padding: 0px
							border-radius: 15px
							width: 60%
							white-space: pre-wrap
			section.SectionSecondary
				div.DivSiteContainer
					h1
						font-size: 2.5em
						margin-top: 30px
						margin-bottom: 30px
					div.DivContentImageGallery
						padding: 0
						height: auto
						margin-bottom: 40px
						div.DivButtonNavigate
							width: 30px
							height: 25px
							img
								width: 10px
								height: 10px
								padding: 10px
								border-radius: 8px
						section#sectionImageGallery
							height: 130px
							label.LabelImageNonActive
								margin-top: 0
								width: 140px
								justify-content: space-evenly
								img
									width: 80px
									margin: 0 auto
								&:first-of-type, &:nth-child(2), &:nth-child(4), &:last-of-type
									display: none
								p
									width: max-content
									font-size: 1em
					div#divProductDetailContainer
						div.DivDescriptionDetailContainer
							h2
								font-size: 1.8em
					div.DivFormHorizontalContainer
						button.ButtonMore
							padding: 10px 30px
							border-radius: 30px
							border: solid 4px #F37024
							margin-top: 20px
				div#divSiteProductList
					grid-template-columns: repeat(2, 1fr)
					grid-gap: 10px
					div#divContentProductList
						height: 310px !important
						font-size: 90%
						flex-basis: 0
						padding: 10px
						margin: 0
						margin-bottom: 35px
						div.DivTitleContentHeader
							img
								width: 40px
								height: 40px
								margin: 10px
								margin-left: 5px
							label
								font-size: 1.2em
						p
							line-height: 21px
							margin: 0
							margin-top: 0
							margin-left: 5px
							font-size: 1.2em
						button.ButtonSubmit
							font-size: 0.8em
							height: 32px
							left: 25px
							right: 25px
							padding: 5px 10px
							border-radius: 10px
						img.ImageLabel
							width: 135px !important
							top: 85% !important
				div#divBodyTabAbout
					div#divContentPolicy
						div.DivPolicyList
							font-size: 75%
							padding: 10px
							border-radius: 20px
							div.DivPolicyContentImage
								width: 50px
								height: 50px
								img
									width: 50px
									height: 50px
							div.DivLabelPolicyProductTitle
								margin: 0 10px
								min-width: 100px
								label
									font-size: 1.5em
							div.DivPolicyContentInformation
								p
									line-height: 20px
// SCREEN WIDTH 428px - END


// SCREEN WIDTH 376px - START

@media screen and (max-width: 376px)
	body
		font-size: 75%
		div#divCurtainLoading
			div#divLoadingMessage
				img#imageLoadingCircle
					margin: 0
		div#divMainLandingContainer
			section.SectionMain
				position: relative
				background-position-x: 60%
				height: 100vh
				img.ImgShapeMain1
					width: 125%
					margin-left: -28%
				img.ImgShapeMain2
					height: 10%
				div#divMainLanding
					flex-wrap: nowrap
					flex-direction: column
					margin-left: 30px
					margin-right: 30px
					div.DivHeaderCenterContainer
						margin-top: 125%
						margin-right: 10%
						justify-content: flex-end
						height: max-content
						flex-grow: 3
						flex-shrink: 1
						flex-basis: auto
						div.DivHeaderContentContainer
							font-size: 70%
							// background-color: rgba(29, 86, 52, 0.8)
							box-sizing: border-box
							padding: 10px 15px
							border-radius: 25px
							width: 80%
						h1
							margin: 0
							line-height: 40px
						p
							margin: 0
							width: 100% !important
							margin-top: 5px
							font-size: 1.2em !important

	// START PRODUCT INSURTECH

	div#divMainProductContainer, div#divMainSectionContainer
		section#sectionHeaderProduct, section#sectionPackageProduct
			height: calc((100vh/3)*2)
			background-position-x: 60%
			img.ImgShapeMain1
				width: 100%
				margin-left: -19%
			img.ImgShapeMain2
				height: 25%
			div.DivFormVerticalContainer
				justify-content: flex-end
				div.DivProductHeaderContainer
					display: block
					width: 75% !important
					background-color: rgb(109 110 113 / 46%)
					padding: 20px
					border-radius: 25px
					margin-bottom: 20px
				div.DivProduct
					flex-direction: column
					align-self: flex-start
					margin: 0
					img
						width: 55px
						height: 55px
						margin: 0
					h2
						font-size: 1.3em
						line-height: 40px
						margin: 0
					h2#h2TitleBicycle
						text-align: left
				div.DivProductDetail
					margin: 0
					padding-bottom: 0
					h3
						width: 50%
						font-size: 1.1em
						margin-top: 0
						margin-bottom: 0
					p
						width: 50%
						line-height: 20px
						font-size: 1.1em
			div#divFormVerticalContainerContent
				height: auto
			div.DivSiteContainer
				form
					div.DivForm
						padding: 20px
						fieldset
							margin-top: 0
							div.DivFormHorizontalContainer
								div.DivForm2Column
									margin-bottom: 0px
									span#numberModelProductGeneralPremiumTotalAmount, span#spanPaymentVirtualAccountNumber, span#spanPaymentTotal
										font-size: 1.7em
				div.DivFormHorizontalContainer
					div.DivForm2Column
						p
							line-height: 30px
						div.DivProductPackage
							flex-wrap: nowrap
							justify-content: space-evenly
							margin-top: 0
							margin-bottom: 30px
							div.DivContentBox
								width: 100px
								height: 100px
								img
									width: 70px
									height: 70px
									margin-bottom: 30px
		section#sectionPackageProduct
			height: 100%
			div.DivFormVerticalContainer
				height: auto
				h2
					margin-top: 20px
					font-size: 2em
			div.DivFormHorizontalContainer
				div.DivForm2Column
					h2
						font-size: 2em
					div.DivProductPackage
						justify-content: center
						div.DivContentBox
							margin-left: 0
							flex-basis: auto
					button
						width: 100%
			div.DivSiteContainer
				margin-top: 40px
				div.DivForm2Column
					margin-top: 0
					form
						margin-top: 0
					div.DivRadioButtonMultiple
						label
							max-width: 80%
				div.DivContentBox
					margin-top: 30px
				div.DivRadioButtonContainer
					flex-direction: column
					margin: 0
					div.DivRadioButtonProduct
						display: flex
						margin-top: 10px
						margin-bottom: 5px
					div.DivRadioButton
						height: 18px
						width: 18px !important
						margin: auto
						margin-right: 10px
						margin-left: 0
					label
						margin: auto 0
				.mat-checkbox
					.mat-checkbox-label
						p
							line-height: 25px
				div.DivInputHorizontalContainer
					input[type=number]
						min-width: 0
				div.DivCheckBoxContainer
					width: 100%
				div.DivDownloadImage
					padding-right: 15px
					label
						max-width: 100px
						overflow: hidden
						text-overflow: ellipsis
					input[type=button]
						width: 100px
						padding-left: 30px
						padding-top: 0px
						background-position-x: initial
						box-sizing: border-box
						border-radius: 0px
						font-size: 1.092em !important
		section#sectionCheckoutContainer
			height: 100%
			margin-bottom: 30px
			h3
				width: 100%
				margin-top: 20px
			form
				margin-top: 10px
				margin-bottom: 0
				div.DivForm, PaddingLR0
					padding-top: 10px
					padding-bottom: 10px
			div.DivSiteContainer
				margin-top: 40px
				margin-bottom: 40px
			div.DivForm2Column, WidthAuto
				width: 100% !important
				margin: auto
				margin-bottom: 15px
				padding: 0
				label, span
					font-size: 1em
				div.DivCheckoutContainer
					margin-top: 20px
				span#spanModelProductGeneralPremiumTotalAmount, span#spanPaymentVirtualAccountNumber, span#spanPaymentTotal
					font-size: 1.5em !important
				input[type=button]
					font-size: 1em
				div.DivListContentLong, div.DivListContentLongActive
					p
						font-size: 1em

	// END PRODUCT INSURTECH

// SCREEN WIDTH 375px - END


// SCREEN WIDTH 360px - START

@media screen and (max-width: 361px)

	div#divMainDashboard
		section#sectionMobileApplication
			div#divContentCart
				div.DivCartList
					div.DivCartOrderDetail
						label, h3
							font-size: 70%
						p, h5
							font-size: 75%
							line-height: 15px
					div.DivCartOrderProductList
						h3, p
							font-size: 70%
	div#divMainSectionContainer
		section.SectionSecondary
			div.DivSiteContainer
				div.DivFormHorizontalContainer
					div.DivForm2Column
						p
							line-height: 18px

// SCREEN WIDTH 360px - END
