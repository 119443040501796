/* @charset "utf-8" */
/* CSS Document */

// IMPORT - START

@import form-initialization-general
@import form-setup

// IMPORT - END


// GENERAL - START

form
	width: 100%
	div.DivForm
		padding: $SPACE_MEDIUM
		margin-bottom: $SPACE_MEDIUM
		fieldset
			.divMatFormFieldPeriod
				width: 100%
			border: none
			padding: 0px
			margin: 0px
			margin-bottom: $SPACE_LITTLE
			margin-top: $SPACE_LITTLE
			div.DivInputHorizontalContainer
				margin: 0px
				padding: 0px
				+setBorderRadius(0px)
				display: flex
				justify-content: space-between
				label
					margin-right: $SPACE_LITTLE !important
				input.InputPrice
					margin-top: 0
					margin-bottom: 20px !important
					border: none
					+setBorderRadius($SPACE_TINY)
		fieldset:last-of-type
			margin-bottom: 0px
	div.DivFormBox
		+setBorderRadius(30px)
		+boxShadow(0, 0, 8px, 0, rgba(0, 0, 0, 0.2))
		fieldset
			margin: 30px
			width: auto
	ul.UlListAsignee
		display: flex
		flex-flow: row wrap
		align-content: flex-start
		justify-content: space-between
		li
			box-sizing: border-box
			margin: 0
			width: 250px
			height: auto

div.DivForm
	div.DivPercentageProgress
		position: relative
		height: 40px
		width: 100%
		+setBoxSizing(border-box)
		+setBorderRadius($RADIUS_LAYOUT_FORM)
		div#divSubPercentageProgress
			position: absolute
			height: 40px
			width: 30%
			overflow: hidden
			+setBoxSizing(border-box)
			+setBorderRadius($RADIUS_LAYOUT_FORM)
		span
			position: relative
			color: white
			margin-left: 44%
			padding-top: 10px
			+setBoxSizing(border-box)
			+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivForm
	div.DivCard, div.DivCardFinish
		width: 100%
		height: 100%
		margin-bottom: 10px
		margin-top: 5px
		div.DivContainerIcon
			position: relative
			display: flex
			flex-direction: row
			justify-content: flex-end
			padding: 0px
			div.DivBorderImage
				display: flex
				justify-content: center
				align-items: center
				width: 32px
				height: 32px
				margin-right: 10px
				background-color: white
				+setBorderRadius(50%)
				img#imageQoute
					width: 26px
					height: 24px
					object-fit: cover
				img#imageApprove
					width: 26px
					height: 24px
					object-fit: cover

div.DivCard, div.DivCardFinish
	margin-top: $SPACE_SMALL
	margin-bottom: $SPACE_SMALL
	padding-left: $SPACE_MEDIUM
	padding-right: $SPACE_MEDIUM
	padding-bottom: $SPACE_MEDIUM
	padding-top: $SPACE_MEDIUM
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivContainerIcon
	padding-left: 10px
	padding-right: 10px
	padding-bottom: 10px
	padding-top: 10px
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivVerifierGeneral, div.DivCenterGeneral
	position: relative
	margin: $SPACE_ENORMOUS
	img#imageLogoHeader
		position: inherit !important
		top: 0px
		right: 0px
	div#divMainDashboard
		div.DivContainer
			margin-top: $SPACE_HUGE

div.DivMainGeneral, div.DivMainAsideSearch
	margin-top: $SPACE_MEDIUM
	width: 100%
	height: 100%

div.DivMainAsideSearch
	display: flex
	flex-wrap: nowrap
	justify-content: space-between
	align-content: stretch
	flex-basis: $WIDTH_LAYOUT_SIGNIN

div.DivContainerHorizontal, div.DivContainerVertical
	width: 100%
	height: 100%
	margin-top: $SPACE_MEDIUM
	display: flex
	flex-wrap: nowrap
div.DivContainerListHorizontal
	flex-direction: column
div.DivContainerHorizontal
	flex-direction: row
	div.DivContainer
		margin-left: $SPACE_SMALL
		margin-right: $SPACE_SMALL
		display: flex
		flex-direction: column
	div.DivContainer:first-of-type
		margin-left: 0px
	div.DivContainer:last-of-type
		margin-right: 0px
div.DivContainerVertical
	flex-direction: column

div.DivForm, div.DivFormBranch
	margin-top: $SPACE_SMALL
	margin-bottom: $SPACE_SMALL
	padding-left: $SPACE_MEDIUM
	padding-right: $SPACE_MEDIUM
	padding-bottom: $SPACE_MEDIUM
	padding-top: $SPACE_MEDIUM
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

section
	div.DivFormListHorizontalContainer
		display: flex
		justify-content: space-around
		align-content: stretch
		align-items: flex-end
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer
		display: flex
		justify-content: flex-start
		align-content: stretch
		align-items: baseline
		flex-wrap: wrap // nowrap
		div.DivListChart
			margin-top: $SPACE_MEDIUM
			align-self: flex-start
			ul
				height: $HEIGHT_CHART_LIST
				overflow-y: scroll
		div.DivDownloadImage
			display: flex
			flex-direction: row
			justify-content: space-between
			align-items: center
			background-color: #F7f7f7
			border-radius: 10px
			padding-left: 15px
			width: 95%
			box-sizing: border-box
			input[type=button].ButtonDownload
				height: 30px !important
				margin: 0 !important
				color: #265c8c
				width: fit-content
				padding-left: 35px
				background-position-x: initial
	div.DivFormHorizontalContainer, div.DivFormListHorizontalContainer
		flex-direction: row
		div.DivForm2Column
			margin: $SPACE_SMALL
			span.SpanCheckoutAmount
				color: black
				font-size: 2em !important
			div.DivDateTimePicker
				label
					font-family: "Poppins-SemiBold"
				mat-form-field
					// margin-top: 0px !important
					box-sizing: border-box
					.mat-form-field-wrapper
						padding: 0px
						.mat-form-field-flex
							height: 50px
							padding-top: 0px
							border: none
							background-color: #EFEFEF
							.mat-form-field-label-wrapper
								label
									font-size: 1em !important
									font-family: "Poppins-Regular"
									color: #767676
							.mat-form-field-infix
								border: none
								padding-bottom: 0px
								padding-top: 25px
								padding-left: 0px
								input
									+setBorderRadius(0px)
									color: #000
							.mat-form-field-suffix
								margin: 0
		div.DivForm2Column:first-of-type
			margin-left: 0px
		div.DivForm2Column:last-of-type
			margin-right: 0px
		div.DivForm2ColumnNoMargin
			margin-left: 0px
			margin-right: 0px
			div.DivImageCapture
				img
					width: 95%
					height: 95%
					margin: 0
			// div.DivDownloadImage
			// 	display: flex
			// 	flex-direction: row
			// 	justify-content: space-between
			// 	align-items: center
			// 	background-color: #F7f7f7
			// 	border-radius: 10px
			// 	padding-left: 15px
			// 	width: 95%
			// 	box-sizing: border-box
			// 	input[type=button].ButtonDownload
			// 		height: 30px !important
			// 		margin: 0 !important
			// 		color: #265c8c
			// 		width: fit-content
			// 		padding-left: 35px
			// 		background-position-x: initial
		div.DivForm2ColumnNoMargin:last-of-type:nth-of-type(odd)
			div.DivImageCapture
				width: 50%
		div.DivCheckoutContainer
			width: 10%
			input[type=button]
				margin: 0 !important
				border: none
				border-radius: 50px
				font-family: 'Poppins-SemiBold' !important
				font-size: 1.1em
	div.DivFormVerticalContainer
		flex-direction: column
		div.DivForm2Column
			margin-top: $SPACE_LITTLE
			margin-bottom: $SPACE_LITTLE
		div.DivForm2Column:first-of-type
			margin-top: 0px
		div.DivForm2Column:last-of-type
			margin-bottom: 0px
	div.DivForm2Column
		flex-grow: 1
		flex-shrink: 1
		width: 45%
		span.SpanActiveStatus, IActiveStatus
			width: $WIDTH_LABEL_POLICYGENERAL_STATUS
			text-align: center

div.DivForm:first-of-type
	margin-top: 0px

div.DivForm:last-of-type
	margin-bottom: 0px

img#imageLogoHeader, img#imageLogoDigital
	margin: auto $SPACE_MEDIUM
// img#imageLogoDigital
// 	margin: $SPACE_MEDIUM 0px auto $SPACE_MEDIUM

// GENERAL - END


// NAVIGATION - START

nav
	position: relative
	width: $SIZE_LAYOUT_PROFILECONTAINER
	figure
		margin-bottom: $SPACE_BIG
		div, img#imageProfilePicture, span#spanProfileNameInitial
			border-style: solid
			+setBorderRadius(50%)
		div
			position: relative
			margin-left: auto
			margin-right: auto
			margin-bottom: $SPACE_MEDIUM
			width: $SIZE_LAYOUT_PROFILEFRAME
			height: $SIZE_LAYOUT_PROFILEFRAME
			padding: $SPACE_TINY
			border-width: $WIDTH_BORDER_PROFILETHIN
			img#imageProfilePicture, span#spanProfileNameInitial
				width: $SIZE_LAYOUT_PROFILEIMAGE
				height: $SIZE_LAYOUT_PROFILEIMAGE
				border-width: $WIDTH_BORDER_PROFILETHICK
			span#spanProfileNameInitial
				width: $WIDTH_LAYOUT_PROFILENAMEINITIAL
				height: $HEIGHT_LAYOUT_PROFILENAMEINITIAL
				padding-top: $SPACE_SMALL
				padding-bottom: $SPACE_SMALL
	ul.ListNavigation
		li.ListItemNavigation
			ul.ListSubNavigation, ul.ListSubNavigationCalculatePremium
				padding-left: $SPACE_SMALL
	img#imageIconHamburger
		height: 25px
		width: 25px

// NAVIGATION - END


// HOME - START

div#divBodyHome
	position: absolute
	top: 0px
	left: 0px
	right: 0px
	bottom: 0px
	display: flex
	justify-content: center
	flex-direction: column
	perspective: 1000px
	main
		position: absolute
		padding: $SPACE_ENORMOUS
		top: 0px
		left: 0px
		right: 0px
		bottom: 0px
		+setTransformStyle(preserve-3d)

// img#imageLogoHeader
// 	position: absolute
// 	width: ($WIDTH_LAYOUT_LOGOHEADER + $SPACE_LITTLE)
// 	height: $HEIGHT_LAYOUT_LOGOHEADER
// 	top: ($SPACE_SMALL+$SPACE_MEDIUM)
// 	left: $SPACE_BIG

img#imageLogoHeaderMinimize
	height: 27px
	object-fit: cover

div#divBodyHome
	img#imageLogoHeader
		top: $SPACE_ENORMOUS
		right: $SPACE_ENORMOUS

// HOME - END


// MENU - START

nav#navigationMainHome
	position: fixed
	top: $SPACE_MEDIUM
	right: $SPACE_MEDIUM
	bottom: $SPACE_NONE
	left: $SPACE_MEDIUM
	display: flex
	margin: $SPACE_NONE
	height: $HEIGHT_LAYOUT_HOME_MENU
	width: auto
	z-index: 16
	+setBorderRadius($SPACE_MEDIUM)
	+boxShadow(0, 0px, 8px, 0, rgba(0, 0, 0, 0.2))
	background-color: #FFF
	img#imageLogoHeader
		height: $HEIGHT_LAYOUT_HOME_IMAGE
	img#imageLogoDigital
		height: 22px
		margin-right: 20px
		margin-top: 15px
		margin-bottom: 15px
		cursor: pointer
	img#imageLogoCart
		cursor: pointer
		height: $HEIGHT_LAYOUT_HOME_IMAGE_CART
	ul
		margin: $SPACE_NONE
		display: flex
		flex-wrap: wrap
		color: black
		li
			margin: auto $SPACE_SMALL
			padding: $SPACE_LITTLE $SPACE_TINY
	div.DivNavigationRight
		margin: $SPACE_NONE $SPACE_NONE $SPACE_NONE auto
		display: flex
		flex-direction: row
		justify-content: center
		align-items: center
		li.ListNavigationMenu
			position: relative
			display: inline-block
			margin-left: 20px
			a.ANavigationMenu
				img.ImageIconMenu
					display: block
					margin: auto
					height: 20px
					width: 20px
			div.DivNavigationDetail
				display: none
				position: absolute
				background-color: #FFFFFF
				min-width: 40px
				z-index: 3
				padding: 10px
				border-radius: 0px 0px 20px 20px
				flex-direction: column
				margin-left: -15px
				box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 20px 20px 0 rgba(0, 0, 0, 0.19)
		li.ListNavigationMenu:hover div.DivNavigationDetail
			display: flex

nav.NavMenuOverlay
	height: 100%
	width: 0%
	position: fixed
	z-index: 1
	top: 0
	right: 0
	background-color: white
	background-color: #ffffff
	overflow-x: hidden
	transition: 0.5s
	z-index: 16
	div.DivMenuHeader
		display: flex
		margin: 15px 20px
		img
			height: 50px
		img#imageLogoDigital
			height: 20px
			margin-left: 0px
		img.ImageIconMenu
			margin-right: -5px
	ul.ListNavigation
		margin: 20px 10px
		list-style: none
		li.ListNavigationMenu, li.ListItemNavigation
			margin: 5px 0px
			padding: 10px
			padding-bottom: 10px
			color: black
			font-size: 1.34em
			ul
				margin-top: 10px
				li.ListItemSubNavigation
					padding: 10px
		li.ListNavigationMenuActive, li.ListNavigationMenu:hover, li.ListItemSubNavigation:hover
			background-color: orange
			+setBorderRadius(5px)
			a, label
				color: #ffffff
		li.ListItemNavigation
			padding-bottom: 5px
			label
				color: black
		li.ListNavigation
			padding-bottom: 5px
			ul
				margin: 0
		img#imageMainDomain
			height: 30px
			margin: 10px
nav.NavMenuMinimizeOverlay
	width: 100%

// MENU - END


// LOADING - START

div#divCurtainLoading
	position: fixed
	top: 0px
	left: 0px
	right: 0px
	bottom: 0px
	display: none
	z-index: 10
	div#divLoadingBar
		width: 0%
		height: $HEIGHT_LOADING_BAR
		display: none
	div#divLoadingMessage, img#imageLoadingCircle
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		margin: 20px auto
	div#divLoadingMessage
		width: 100%
		height: 100%
		img#imageLoadingCircle
			width: 100px
			height: 100px
			background-color: none
		span
			text-align: center

// LOADING - END

div#divBodyDashboard
	div#divMainDashboard
		width: 100%
		height: 100%
		margin-top: $SPACE_MEDIUM
	div#divMainDashboard
		display: flex
		flex-wrap: nowrap
	div.DivCenterContainer
		margin-top: $SPACE_ENORMOUS
		display: block
		width: 100%
		position: relative
		margin-left: auto
		margin-right: auto
		+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
		+setBorderRadius(25px)
		div.DivForm
			div.DivHeaderHeadline
				display: flex
				justify-content: space-between
				div.DivHeaderStatus
					display: flex
					align-items: flex-start
					justify-content: space-between
					span
						text-align: center
						margin-left: $SPACE_LITTLE
						margin-right: $SPACE_LITTLE
						margin-top: 0px
						padding: $SPACE_LITTLE
						+setBorderRadius($RADIUS_BUTTON_GENERAL)
					span:last-of-type
						margin-right: 0px
				div.DivSubHeaderHeadline
					label
						margin: 0
					h2
						margin-top: 5px
						margin-bottom: 5px
			fieldset
				margin-top: 0px
				div.DivForm2Column
					div.DivHeaderInfo
						margin-top: $SPACE_LITTLE
						display: flex
						justify-content: space-between
						dl
							margin: 0px
							flex-grow: 1
							flex-basis: 0
							dd
								margin: 0
				div.DivFormHorizontalContainer
					div.DivHeaderList
						align-self: flex-start
						ul
							li
								padding: 5px 10px
								width: fit-content
								margin-right: 0
								margin-bottom: 5px
								margin-top: 5px
								span
									margin: 0
								+setBorderRadius(9px)
							li:first-of-type
								margin-top: 0px
							li:last-of-type
								margin-bottom: 0px

/* TABLE - START */

div.DivTableListContainer, div.DivTableListContainerLeft
	width: 100%
	height: 100%
	div.DivTableListControl, div.DivTableListControlLeft
		display: flex
		align-items: center
		div.DivTableListSubControl
			display: flex
			justify-content: space-between
			align-items: center
	div.DivTableListControl
		justify-content: space-around
	div.DivTableListControlLeft
		justify-content: flex-end
		margin-bottom: $SPACE_LITTLE

table
	div.DivContentButtonDetail
		a.ButtonForwardTable
				position: relative
				margin: auto
				padding: 18px 16px
				transition: all 0.2s ease
				background-color: transparent
				text-decoration: none
		a.ButtonForwardTable:before
				content: ""
				position: absolute
				top: 0
				left: 0
				display: block
				border-radius: 45px
				width: 60px
				height: 60px
				transition: all 0.3s ease
		a.ButtonForwardTable svg
				position: relative
				top: 0
				margin-left: 10px
				fill: none
				stroke-linecap: round
				stroke-linejoin: round
				stroke: #F2F2F2
				stroke-width: 2
				transform: translateX(-5px)
				transition: all 0.3s ease
				background-color: transparent
		a.ButtonForwardTable:hover:before
				width: 100%
		a.ButtonForwardTable:hover svg
				transform: translateX(0)
		a.ButtonForwardTable:active
				transform: scale(0.96)

/* TABLE - END */

//START - FOOTER

div#divBodyFooterContainer
	border-top: solid 2px #dcdcdc
	div.DivFooterInfoPage
		display: flex
		flex-direction: row
		justify-content: space-between
		padding: 120px 60px 0px 60px
		height: 85vh
		background: #FFFFFF
		div.DivFooterVerticalContainer
			width: 450px
			label
				display: flex
				font-size: 17pt
				font-weight: 600
				margin-bottom: 10px
			label.labelHeaderTitleCenter
				justify-content: center
			div.DivFooterHorizontalContainer
				display: flex
				flex-direction: row
				div.DivParagraphLink
					a
						text-decoration: none
				div.DivFooterSVGContainer
					width: 40px
					height: 40px
					img
						display: block
						width: 20px
						height: 20px
						margin: 9px auto
				div.DivFooterSVGLargeContainer
					img
						height: 60px
				div.DivFooterSVGLargeContainer:nth-child(1)
					margin: 10px 20px 10px 0px
				div.DivFooterSVGLargeContainer:nth-child(2)
					margin: 10px 0px 10px 20px
				div.DivFooterSVGMediumContainer
					position: relative
					margin: 10px 20px
					img
						height: 40px
				div.DivFooterSVGMediumContainer:nth-child(1)
					margin: 10px 20px 10px 0px
					img
						bottom: 0px
						height: 70px
				div.DivFooterSVGMediumContainer:nth-child(2)
					margin: 10px 0px 10px 20px
					img
						margin-top: 30px
			div.DivFooterSocialMedia
				margin-top: 20px
				margin-bottom: 50px
				div.DivFooterHorizontalContainer
					justify-content: flex-start
					margin: auto
					div.DivFooterSVGContainer
						width: 60px
						img
							display: block
							width: 30px
							height: 30px
							margin: 10px 20px
							margin-left: 0
		div.DivFooterPageHorizontalContainer
			display: flex
			flex-direction: column
			justify-content: center
			div.DivFooterSectionPage
				display: flex
				justify-content: space-between
				height: 100%
				div#divFooterSubscribeContainer
					width: 900px
					margin-top: 50px
					div.DivFooterVerticalContainer
						width: auto
						div.DivFormSubscribeEmail
							width: 70%
							button
								img
									display: block
									margin: auto
									height: 20px
									width: 20px
	div.DivFooterContentContainer
		margin-bottom: $SPACE_MEDIUM
	div.DivFormSubscribeEmail
		position: relative
	div.DivHorizontalContainerCenter
		justify-content: center
	p.PFooterContact
		margin: 5px 10px
	div.DivFooterCopyright
		display: flex
		flex-direction: column
		margin-bottom: 0px
		margin-top: 10px
		hr
			margin: 0
			width: 200px
			background-color: #064F89
	div.DivFooterCopyrightResponsive
		display: none
		flex-direction: column
		margin-bottom: 0px
		margin-top: 10px
		hr
			margin: 0
			width: 200px
			background-color: #064F89
//END - FOOTER


// START - TABLE

div.DivTableContainer
	width: 100%
	height: 100%
	div.DivTableControl
		display: flex
		justify-content: space-between
		align-items: center
		div.DivTableSubControl
			display: flex
			justify-content: space-between
			align-items: center
			label
				span
					margin-left: 5px
					margin-right: 5px
	ul.ListTableThumbnails, table, ul.ListTableTicket, ul.ListTableConversation
		margin-top: $SPACE_LARGE
		margin-bottom: $SPACE_LARGE
	ul.ListTableThumbnails, ul.ListTableTicket, ul.ListTableConversation
		display: flex
		list-style-type: none
		+setBorderRadius($RADIUS_LAYOUT_FORM)
		li
			margin: 0px
			padding: $SPACE_LITTLE
	ul.ListTableThumbnails
		justify-content: flex-start
		align-content: stretch
		align-items: baseline
		flex-wrap: wrap
		li
			width: $WIDTH_TABLE_THUMBNAILSFIGURE
			// height: $HEIGHT_TABLE_THUMBNAILSFIGURE
			height: auto
			figure
				position: relative
				margin-left: auto
				margin-right: auto
				text-align: center
				img
					width: $SIZE_TABLE_THUMBNAILSICON
					height: $SIZE_TABLE_THUMBNAILSICON
					margin-bottom: $SPACE_LITTLE
					border: none
				figcaption
					word-wrap: normal
					white-space: normal
					word-break: break-all
	table
		border-collapse: collapse
		width: 100%
		thead, tbody
			tr
				td, th
					padding-top: $SPACE_MEDIUM
					padding-left: $SPACE_LITTLE
					padding-right: $SPACE_LITTLE
					padding-bottom: $SPACE_MEDIUM
					word-wrap: normal
					white-space: normal
					word-break: normal
					figure
						img
							width: $SIZE_TABLE_THUMBNAILSICONSMALL
							height: $SIZE_TABLE_THUMBNAILSICONSMALL
				td:first-of-type, th:first-of-type
					padding-left: $SPACE_MEDIUM
				td:last-of-type, th:last-of-type
					padding-right: $SPACE_MEDIUM
		thead
				th:first-of-type
					+setBorderRadiusLeft($RADIUS_TABLE_GENERAL)
				th:last-of-type
					+setBorderRadiusRight($RADIUS_TABLE_GENERAL)
		tbody
			tr:first-of-type
				td:first-of-type
					+setBorderRadiusTopLeft($RADIUS_TABLE_GENERAL)
				td:last-of-type
					+setBorderRadiusTopRight($RADIUS_TABLE_GENERAL)
			tr:last-of-type
				td:first-of-type
					+setBorderRadiusBottomLeft($RADIUS_TABLE_GENERAL)
				td:last-of-type
					+setBorderRadiusBottomRight($RADIUS_TABLE_GENERAL)
	table.TableListDetails
		padding: 0px
		margin-top: $SPACE_LITTLE
		margin-bottom: 0px
		max-width: $WIDTH_TABLE_ATTACHMENT
		table-layout: fixed
		td
			padding: 0px
			figure
				margin: 0px
				img
					margin-top: $SPACE_TINY
		td:first-of-type
			text-align: right
			padding-left: 0px
		td:nth-child(1)
			width: $WIDTH_TABLE_ATTACHMENTSMALL
		td:nth-child(2)
			width: $WIDTH_TABLE_ATTACHMENTBIG
			overflow: hidden
			text-overflow: ellipsis
		td:nth-child(3)
			width: $WIDTH_TABLE_ATTACHMENTMEDIUM
		td:nth-child(4)
			width: $WIDTH_TABLE_ATTACHMENTSMALL
			padding-right: 0px
	table.TableListDetailsAttachment
		td:last-of-type
			position: relative
			align-items: center
			label
				margin-right: auto !important
				margin-left: auto !important
	ul.ListTableTicket, ul.ListTableConversation
		flex-direction: column
		li
			width: 100%
			padding-top: $SPACE_SMALL
			padding-left: $SPACE_MEDIUM
			padding-right: $SPACE_MEDIUM
			padding-bottom: $SPACE_SMALL
			display: flex
			align-items: center
			+setBoxSizing(border-box)
			img
				margin-right: $SPACE_MEDIUM
				width: $SIZE_ICON_LDPI
				height: $SIZE_ICON_LDPI
			div.DivTicketItem, div.DivConversationGeneral
				display: flex
				flex-direction: column
				flex-grow: 1
				div.DivTicketSubItem, div.DivConversationItem, div.DivTicketContentItem
					display: flex
					justify-content: space-between
					dl
						margin: 0px
						flex-grow: 1
						flex-basis: 0
					div.DivConversationStatus
						display: flex
						align-items: flex-start
					div.DivConversationHeader
						display: flex
						flex-flow: row wrap
						align-items: center
						justify-content: flex-start
						div.DivConversationProfile
							+setBorderRadius(50%)
							span.SpanChatProfileNameInitial
								width: $SPACE_BIG
								height: $SPACE_BIG
								display: flex
								align-items: center
								justify-content: center
						div.DivConversationSubHeader
							span.SpanConversationTitle
								max-width: 200px
					div.DivTicketTail
						span.SpanTicketAlert
							width: 7px
							height: 17px
							background-color: red
							margin-bottom: 0
							+setBorderRadius(50%)
							border-style: solid
			div.DivTicketSubItem:first-of-type, div.DivConversationItem:first-of-type
				margin-bottom: $SPACE_TINY
			div.DivTicketSubItem:last-of-type, div.DivConversationItem:last-of-type
				margin-top: $SPACE_TINY
		li:first-of-type
			+setBorderRadiusTop($RADIUS_LAYOUT_FORM)
		li:end-of-type
			+setBorderRadiusBottom($RADIUS_LAYOUT_FORM)
	ul.ListTableConversation
		li
			+setBorderRadius($RADIUS_LAYOUT_FORM)
		li.LiConversationContainerRight, li.LiConversationContainerLeft
			margin-top: $SPACE_MEDIUM
			width: 80%
			+setBoxSizing(border-box)
		li.LiConversationContainerRight
			align-self: flex-end
			div.DivConversationItem
				div.DivConversationHeader
					span
						text-align: right
					div.DivConversationProfile
						margin-left: $SPACE_MEDIUM
						margin-right: 0px
		li.LiConversationContainerLeft
			align-self: flex-start
			flex-direction: row-reverse
			div.DivConversationItem
				flex-direction: row-reverse
				div.DivConversationHeader
					flex-direction: row-reverse !important
					span.SpanConversationDate
						text-align: left
					div.DivConversationProfile
						margin-left: 0px
						margin-right: $SPACE_MEDIUM
		li:first-of-type
			margin-top: 0px

// TABLE - END


// BRINS DIGITAL - START

div#divMainDashboard
	form
		div.DivForm
			fieldset
				input[type=text], input[type=number], select
					box-sizing: border-box
					+setBorderRadius($SPACE_TINY)
					background-color: #efefef
					border: none
	fieldset
		border: none
	section.SectionMain
		display: flex
		width: 100%
		height: 100vh
		background-size: cover
		div.DivFormHorizontalContainer
			margin-left: $HEIGHT_LAYOUT_MARGIN_SIDE
			margin-right: calc($HEIGHT_LAYOUT_MARGIN_SIDE - $SPACE_HUGE)
	section.SectionSecondary
		width: 100%
		height: max-content
		div#divSiteProductList
			display: grid
			grid-template-columns: repeat(auto-fill, minmax(295px, 1fr))
			margin-bottom: 20px
			div#divContentProductList
				padding: 15px
				flex-basis: auto
				height: 400px !important
				margin: 45px 15px
				box-sizing: border-box
				margin-top: 0px
				box-shadow: none
				background-color: #F1F2F2
				img
					width: 50px
					height: 50px
					margin: 15px
				img.ImageLabel
					width: 155px
					height: auto
					position: absolute
					left: 50%
					top: 87%
					transform: translate(-50%,-50%)
					margin: 0
				p
					font-size: 0.9em
					text-align: left
					line-height: 28px
					font-family: "Poppins-Light"
					margin: $SPACE_TINY $SPACE_LITTLE 0px $SPACE_LITTLE
					overflow-y: hidden
					display: -webkit-box
					-webkit-line-clamp: 8
					-webkit-box-orient: vertical
				div.DivTitleContentHeader
					display: flex
					label
						margin: auto
						margin-left: 0px
						font-family: "Poppins-SemiBold"
				button
					position: absolute
					bottom: -20px
					left: 60px
					right: 60px
					font-family: "Poppins-SemiBold"
					label
						font-size: 1.1em
		div#divSitePromotionList
			margin-bottom: 50px
			div.DivPromotionHorizontal
				display: flex
				flex-direction: row
				margin-bottom: 50px
				overflow-y: hidden
				div.DivImagePromotion
					margin: 20px
					width: 700px
					img
						display: block
						object-fit: cover
						width: inherit
						+setBorderRadius($RADIUSE_BOX_CONTENT_MEDIUM)
					button.ButtonSubmit
						display: block
						margin: 20px auto
						label
							font-family: "Poppins-Regular"
				div.DivImagePromotion:first-of-type
					margin-left: 0px
					margin-right: 0px
			div.DivPromotion
				display: flex
				flex-direction: row
				flex-wrap: wrap
				margin-bottom: 20px
				img
					margin: 10px
					flex-grow: 1
					flex-shrink: 1
					width: 360px
					height: auto
					+setBorderRadius($RADIUSE_BOX_CONTENT_SMALL)
				img:nth-child(3n+1)
					margin-left: 0px
				img:nth-child(3n)
					margin-right: 0px
			div.DivPromotionDetailHorizontal
				display: flex
				flex-direction: row
				margin-top: 20px
				margin-bottom: 50px
				width: 100%
				div.DivImagePromotion:nth-of-type(1)
					flex: 1.8 1 0
					img.ImageRadius
						width: 700px
						height: auto
						margin-left: 0px
						margin-bottom: 50px
						+setBorderRadius(20px)
				div.DivDetailPromotion:nth-of-type(2)
					flex: 1 1 0
					p
						margin-bottom: 30px
		div.DivSiteContainer
			margin-top: 80px
			margin-bottom: 100px
			div.DivFormHorizontalContainer, div.DivFormVerticalContainer
				img.ImageFormDetail
					height: 450px
					width: 450px
				img.ImageDownload
					width: 150px
					margin-left: 0px
					margin-right: 20px
					margin-top: 30px
					cursor: pointer
				img.ImageRadius
					+setBorderRadius(20px)
			div#divProductWithDetail, div#divProductWithDetailReverse
				align-items: center
				margin-top: 50px
			div#divProductWithDetail
				img.ImageFormDetail
					margin-left: 0px
			div#divProductWithDetailReverse
				display: flex
				flex-direction: row-reverse
				img.ImageFormDetail
					margin-right: 0px
	section.SectionTertiary
		display: flex
		width: 100%
		height: 40vh
		background-size: cover
		background-position-y: 25%
		div.DivFormHorizontalContainer
			margin-top: $HEIGHT_LAYOUT_MARGIN_TOP
			margin-left: $HEIGHT_LAYOUT_MARGIN_SIDE
			margin-right: $HEIGHT_LAYOUT_MARGIN_SIDE
	div.DivContentBox, div.DivContentBoxSmall, div.DivContentBoxBig, div.DivContentSmall
		display: flex
		flex-direction: column
		+boxShadow(0, 0px, 8px, 0, rgba(0, 0, 0, 0.2))
	div.DivContentBox
		+setBorderRadius($RADIUSE_BOX_CONTENT_MEDIUM)
	div.DivContentSmall
		padding: 20px 10px
		+setBorderRadius($RADIUSE_BOX_CONTENT_ENORMOUS)
	div.DivContentBig
		+setBorderRadius($RADIUSE_BOX_CONTENT_BIG)
	div.DivContentFull
		width: 100%
	div.DivContentDetailCoverage
		padding: $SPACE_LITTLE $SPACE_MEDIUM
		form
			margin-bottom: $SPACE_NONE
			margin-top: $SPACE_NONE
			div.DivForm
				padding: $SPACE_LITTLE $SPACE_MEDIUM !important
			div.DivInputHorizontalContainer
				width: 100%
				input
					margin-bottom: 0px
				label
					margin: 18px 0px
	div.DivSiteContainer
		margin: $SPACE_BIG $HEIGHT_LAYOUT_SIDE_CONTAINER
		align-items: center
	img.ImageAbsolute
		position: absolute
	div.DivContainer
		margin: $SPACE_NONE
	div.DivRelativeContainer
		position: relative
		box-sizing: border-box
		flex-basis: 350px
		height: 350px
		padding: 0px
		background-size: cover
		background-position-x: 90%
	div#divParagraphLanding, div#divProductLanding
		padding: 0px 15px
		margin-top: 30px
		width: 300px
		h3
			margin-bottom: 0px
		h3:last-of-type
			margin-bottom: 20px
		p
			margin-bottom: 0px
	div.DivContentImage
		display: flex
		flex-direction: column
		flex-wrap: wrap
		box-sizing: border-box
		min-height: 346px
		max-height: max-content
		justify-content: flex-start
		div.DivFormHorizontalContainer:first-of-type
			margin-bottom: $SPACE_LITTLE
		div.DivProduct
			display: flex
			flex-direction: column
			width: 21%
			height: auto
			margin: $SPACE_NONE 2%
			cursor: pointer
			box-sizing: border-box
			padding: $SPACE_TINY $SPACE_TINY $SPACE_NONE $SPACE_TINY
			+setBorderRadius(20px)
			margin-bottom: auto
			img
				width: $HEIGHT_LAYOUT_SIDE_CONTAINER
				height: $HEIGHT_LAYOUT_SIDE_CONTAINER
				margin-bottom: $SPACE_NONE
		div.DivProduct:hover
			box-shadow: 0 0 0 5px #F38F1E
	div.DivProductPackage
		display: flex
		justify-content: flex-start
		flex-wrap: wrap
		text-align: center
		height: auto
		cursor: pointer
		margin-top: $SPACE_MEDIUM
		margin-bottom: $SPACE_MEDIUM
		div.DivContentBox
			width: 200px
			height: 200px
			padding: 10px 15px
			margin-left: $SPACE_HUGE
			margin-top: $SPACE_LITTLE
			margin-bottom: $SPACE_LITTLE
			box-sizing: border-box
			&:first-of-type
				margin-left: 0px
			img
				margin: auto
				height: 100px
				width: 100px
	ul.UlTabContainer
		padding: $SPACE_TINY
		margin-top: $SPACE_NONE
		margin-bottom: $SPACE_LARGE
		list-style: none
		-ms-box-orient: horizontal
		display: -webkit-box
		display: -moz-box
		display: -ms-flexbox
		display: -moz-flex
		display: -webkit-flex
		display: flex
		-webkit-justify-content: space-around
		justify-content: space-around
		-webkit-flex-flow: row wrap
		display: flex
		-webkit-align-items: stretch
		align-items: stretch
		+setBorderRadius($SPACE_BIG)
		li.ListTab
			flex-shrink: 1
			flex-grow: 1
			flex-basis: 200px
			line-height: $SPACE_ENORMOUS
			color: white
			border: none
			margin-left: $SPACE_THIN
			margin-right: $SPACE_THIN
			+setBorderRadius($SPACE_MEDIUM)
			cursor: pointer
		li.ListTab:first-of-type
			margin-left: $SPACE_NONE
		li.ListTab:last-of-type
			margin-right: $SPACE_NONE
	hr.HorizontalRule
		height: $SPACE_THIN
		width: 100%
		border: none
	label.LabelPromoLight
		display: block
		margin: auto
	ol.OrderedNumber
		margin: $SPACE_NONE $SPACE_HUGE
	fieldset.FieldsetTermsAndConditions
		height: 400px
		overflow-y: scroll
	figure
		margin: 0px
		video, iframe
			+setBorderRadius(20px)
			width: -webkit-fill-available
			margin: 20px 0px
			height: 600px
	span#spanPaymentVirtualAccountNumber
		display: flex
		img.ImageCopy
			height: $HEIGHT_LAYOUT_HOME_IMAGE
			width: $HEIGHT_LAYOUT_HOME_IMAGE
			margin: auto $SPACE_SMALL
	div.DivContentCopy
		height: auto
		max-width: 700px
		display: flex
		+setBorderRadius(15px)
		h2
			display: block
			margin: $SPACE_SMALL auto
		img.ImageCopy
			margin: auto $SPACE_MEDIUM
			height: $HEIGHT_LAYOUT_HOME_IMAGE
			width: $HEIGHT_LAYOUT_HOME_IMAGE
	div.DivTitle2Colour
		display: flex
		h2:nth-child(1)
			margin-right: $SPACE_THIN
		h2:nth-child(2)
			margin-left: $SPACE_THIN

div#divMainSectionContainer, div#divMainProductContainer
	section#sectionHeaderProduct
		position: relative
		display: flex
		width: 100%
		background-size: cover
		height: $HEIGHT_LAYOUT_HEADER_PRODUCTCONTENT
		background-position-y: bottom
		div.DivFormVerticalContainer
			align-content: space-between
			z-index: 1
			width: 100%
			margin-top: $HEIGHT_LAYOUT_MARGIN_TOP
			div.DivProductHeaderContainer
				display: flex
				width: 100%
				margin-top: 40px
			div.DivProduct, div.DivProductTitle
				width: 20%
				h2
					font-size: 4em
					line-height: $FONT_TITLE_LINE_HEIGHT
				img
					margin-top: $SPACE_LITTLE
					margin-bottom: $SPACE_LITTLE
					width: $WIDTH_TABLE_THUMBNAILSFIGURE
					height: $WIDTH_TABLE_THUMBNAILSFIGURE
			div.DivProductTitle
				margin: auto 0px 90px -15px
			div.DivProduct
				margin: auto $SPACE_LITTLE
			div.DivTitleBottom
				margin-bottom: 10px
				margin-left: -20px
			div.DivProductDetail
				margin: auto
				width: 60%
	section#sectionPackageProduct
		div.DivContentDetailCoverage
			div.DivContentCoverageDescription
				display: flex
				img
					width: $SPACE_LARGE
					height: $SPACE_LARGE
					margin: auto $SPACE_LITTLE
					margin-top: $SPACE_LITTLE
	section#sectionMobileApplication
		div.DivSiteContainer
			margin-top: 0px
	section#sectionPartner
		div.DivSiteContainer
			margin-bottom: 0px
	section.SectionSecondary
		div.DivSiteContainer
			h1, h2, h3, li
				color: #6D6E71
			div.DivContentImageGallery
				display: flex
				flex-direction: row
				height: auto
				width: auto
				padding: 10px
				div.DivButtonNavigate
					margin: auto 0px
					width: 55px
					height: 55px
					img
						width: 15px
						height: 15px
						padding: 20px
						+setBorderRadius(15px)
						background-color: #6D6E71
				section#sectionImageGallery
					display: flex
					flex-direction: row
					justify-content: space-evenly
					margin: 0px auto
					position: relative
					width: 100%
					height: 230px
					perspective: 1400px
					transform-style: preserve-3d
					label
						img
							+setBorderRadius(15px)
					label.LabelImageActive, label.LabelImageNonActive
						display: flex
						flex-direction: column
						align-items: center
						img
							width: 150px
							display: block
							margin: auto 40px
						p
							color: black
							font-size: 0.8em
							font-family: "Poppins-SemiBold"
							width: 150px
							text-align: center
							line-height: 20px
					label.LabelImageNonActive
						opacity: 0.5
					label.LabelImageActive
						opacity: 1
		div.DivFormHorizontalContainer
			button.ButtonMore
				padding: 15px 50px
				border-radius: 50px
				font-size: 1em
				font-family: "Poppins-SemiBold"
				box-sizing: border-box
				background: none
				cursor: pointer
	section#sectionCheckoutContainer
		div.DivSiteContainer
			div.DivListContentShort, div.DivListContentShortActive
				display: flex
				flex-wrap: wrap
				flex-direction: row
				margin-bottom: 10px
				padding: 10px 15px
				+setBorderRadius(10px)
				&:last-of-type
					margin-bottom: 0px
				&:hover
					background-color: #F3F3F3
					label
						color: black
				img
					height: 30px
					width: 48px
					margin-right: 20px
					margin-left: 0px
					object-fit: contain !important
				label
					font-family: 'Poppins-SemiBold'
					align-self: center
					margin: 0px
			div.DivListContentShortActive
				background-color: #F3F3F3
				label
					color: black
			div.DivListContentLong, div.DivListContentLongActive
				margin-bottom: 10px
				border-radius: 20px
				padding: 20px
				&:hover
					background-color: #F3F3F3
				&:last-of-type
					margin-bottom: 0px
				img
					height: 50px
					margin: 0
					margin-bottom: 5px
				p
					margin: 0
					font-size: 0.9em
					line-height: 25px
			div.DivListContentLongActive
				background-color: #F3F3F3

button.ButtonSubmit
	padding: $SPACE_LITTLE $SPACE_HUGE
	+setBorderRadius($SPACE_SMALL)
	cursor: pointer

div.DivContentSearch, div.DivContentTableSearch
	display: flex
	flex-direction: row
	+setBorderRadius($SPACE_SMALL)
	height: $SPACE_BIG
	background-color: #FFFFFF
	padding: $SPACE_LITTLE $SPACE_MEDIUM
	width: 80%
	margin-bottom: $SPACE_MEDIUM
	margin-top: $SPACE_MEDIUM
	+boxShadow(0, 0px, 8px, 0, rgba(0, 0, 0, 0.2))
	img
		height: 25px
		width: 25px
		margin: auto 10px
	div.DivContentSearchDetail, div.DivContentSearchDetailFilter
		display: flex
		width: 100%
		margin: 0px 10px
		select
			background-color: #FFFFFF
			border: 0px
			background-image: none
			height: 30px
		select:focus
			outline: none
		input
			background-color: #FFFFFF
			border: 0px
			background-image: none
			height: 50px
			width: 400px
		input:focus
			outline: none
	div.DivContentSearchDetail
		flex-direction: column
	div.DivContentSearchDetailFilter
		select
			height: 50px
div.DivContentTableSearch
	height: 25px
	input
		width: 100%
		border: none

div#divMainLandingContainer
	div#divMainLanding
		width: 100%
		z-index: 15
		div.DivForm2Column:first-of-type
			p
				width: 400px
		div.DivForm2Column:last-of-type
			display: flex
			div.DivSocialMedia
				margin-left: auto
				margin-right: 0px
				display: flex
				flex-direction: column
				img
					width: 30px
					height: 30px
					cursor: pointer
		div.DivSocialMediaCenterContainer
			height: 40%
			align-self: center
		div.DivHeaderCenterContainer
			display: flex
			flex-direction: column
			justify-content: center
			height: 50%
			align-self: center
			p
				width: 500px !important
				margin-top: 0px
				line-height: 2em
				font-size: 1.2em !important
			h1
				font-size: 4em
				margin-bottom: 20px
	section.SectionSecondary
		div#divSiteLandingContainer
			align-items: flex-start
			margin: 60px 80px
			flex-wrap: wrap
		div.DivSiteContainer
			div.DivContentBox
				flex-basis: $WEIGHT_LAYOUT_BOX_CONTENT
				padding: $SPACE_MEDIUM $SPACE_NONE
				margin: auto
				button.ButtonSubmit
					position: absolute
					bottom: -45px
					width: 60%
					left: 50%
					transform: translate(-50%, -50%)
					label
						font-family: "Poppins-Regular"
				label.LabelLanding
					font-family: 'Poppins-Bold'
					font-size: 2em
					color: white
					margin: 0
			div.DivDisplayLogin
				img
					width: 100%
					height: 100%

section#sectionPurchaseContainer
	div.DivForm2Column
		div.DivContentBox
			height: auto !important

div#divMainProductContainer
	section#sectionHeaderProduct
		position: relative
		display: flex
		width: 100%
		background-size: cover
		height: $HEIGHT_LAYOUT_HEADER_PRODUCT
		background-position-y: bottom
		div.DivFormVerticalContainer
			align-content: space-between
			z-index: 1
			width: 100%
			margin-top: $HEIGHT_LAYOUT_MARGIN_TOP
			div.DivProduct
				margin: auto $SPACE_LITTLE
				width: 22%
				img
					margin-top: $SPACE_LITTLE
					margin-bottom: $SPACE_LITTLE
					width: 120px
					height: 120px
				h2
					font-size: 2em
			div.DivProductDetail
				margin: auto
				width: 60%
		div.DivSiteContainer
			margin-left: 20px
			margin-bottom: 20px
	section#sectionPackageProduct
		div.DivContentDetailCoverage
			div.DivContentCoverageDescription
				display: flex
				img
					width: $SPACE_LARGE
					height: $SPACE_LARGE
					margin: auto $SPACE_LITTLE
					margin-top: $SPACE_TINY
					margin-left: $SPACE_NONE

div#divMainSectionContainer
	div#divBodyTabAbout
		margin: 80px 80px 30px 80px
		h1, h2
			color: #6D6E71
		div.DivContentMain
			display: flex
			justify-content: center
			margin: 30px 0px 0px 0px
			div.DivContentTabAbout
				padding: 10px 5px
				+setBorderRadius(40px)
				button
					width: 300px
					padding: 8px
					border: none
					margin: 0px 5px
					cursor: pointer
					+setBorderRadius(25px)
		div.DivStepPayment
			margin-bottom: 30px
	div#divContentCart, div#divContentPolicy
		margin-bottom: 50px
		margin-top: 50px
		div.DivCartList, div.DivPolicyList
			display: flex
			flex-direction: row
			cursor: pointer
		div.DivCartList
			margin-bottom: 25px
			padding: 15px
			cursor: pointer
			div.DivPaymentMethod
				display: flex
				width: 320px
				img.ImageLogoPayment
					height: 80px
				div.DivProductProgressContainer
					margin: auto
					position: relative
					width: 180px
					height: 180px
					.mat-progress-spinner
						position: absolute
						width: 180px !important
						height: 180px !important
						svg
							width: 180px !important
							height: 180px !important
						circle
							stroke-width: 10% !important
					h2
						position: absolute
						left: 50%
						top: 50%
						transform: translate(-50%,-50%)
			div.DivCartOrderDetail
				width: 	900px
			div.DivCartOrderProductList
				display: flex
				flex-direction: column
				width: 250px
				text-align: center
				img
					width: 100px
		div.DivPolicyList
			width: 80%
			margin: 0px auto 25px auto
			box-sizing: border-box
			padding: 20px 50px
			align-items: center
			flex-basis: 150px
			border-radius: 35px
			div.DivPolicyContentImage
				display: flex
				margin: 0 20px 0 0
				width: 120px
				height: 120px
				box-sizing: border-box
				background-size: contain
				background-position-x: center
				background-position-y: center
				img
					width: 120px
				img.ImageBlackAndWhite
					filter: grayscale(1)
			div.DivLabelPolicyProductTitle
				margin: 0 60px 0 20px
				min-width: 170px
				box-sizing: border-box
				text-align: center
				label
					font-family: 'Poppins-Bold'
					font-size: 2em
					color: #064F89
			div.DivPolicyContentInformation
				width: 100%
				label
					font-size: 2em
					font-family: 'Poppins-Bold'
					text-transform: uppercase
					color: #064F89
				div.DivPolicyContentInformationDate
					display: flex
					flex-direction: row
					justify-content: space-between
					div.DivPolicyContentInformationStartDate, div.DivPolicyContentInformationEndDate
						p
							margin: 0
						span
							font-family: 'Poppins-Bold'
							font-size: 1.5em
							color: #D36707

// START - MODAL

div.DivModal
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	margin: auto
	display: none
	width: 80%
	height: max-content
	background-color: #fff
	box-sizing: border-box
	z-index: 9999
	+setBorderRadius(30px)
	div.DivModalHeader
		display: flex
		flex-direction: row
		margin-top: 20px
		margin-left: 30px
		margin-right: 30px
		justify-content: space-between
		img
			height: 50px
			width: 50px
			cursor: pointer
			margin: 0
		h2
			margin: 0px
	div.DivModalFigureContainer
		height: 600px
		figure
			width: auto
			height: auto
			iframe
				height: auto
				+setBorderRadiusBottom(30px)

// END - MODAL


// ANGULAR MATERIAL - START

div.DivFormTextarea
	textarea
		margin: 0 !important
		height: 150px !important
		+setBorderRadius(5px)
		border: none
		background-color: #EFEFEF
		resize: none
	.mat-form-field
		font-family: "Poppins-Regular"
		+setBoxSizing(border-box)
		.mat-form-field-wrapper
			padding: 0px
		.mat-form-field-flex
			+setBorderRadius(5px)
			background-color: #EFEFEF !important
			border: none
			padding: 0
		.mat-form-field-infix
			padding: 0
			border: none
			// textarea
			// 	padding: 0px
			// 	margin: 0px
			// 	+setBoxSizing(border-box)
			// 	+setBorderRadius(5px)
			// .mat-form-field-infix
			// 	border: none

.mat-slider
	height: 0px !important
	width: 100%
	margin-bottom: 10px
	margin-top: 20px
	.mat-slider-wrapper
		height: 5px
		.mat-slider-track-wrapper
			height: 5px
			.mat-slider-track-background
				height: 5px
			.mat-slider-track-fill
				height: 5px
		.mat-slider-ticks-container
			height: 5px
			.mat-slider-ticks
				height: 5px
	.mat-slider-wrapper::after
		height: 5px
	.mat-slider-wrapper::before
		height: 5px
	.mat-slider-thumb
		bottom: -13px
		width: 25px
		height: 25px
.mat-slider-horizontal
	.mat-slider-wrapper
		top: 0px !important
		height: 5px !important
.mat-slider-track-background
	background-color: rgba(0,0,0,.26)
.mat-slider.mat-accent .mat-slider-track-fill, .mat-slider.mat-accent .mat-slider-thumb, .mat-slider.mat-accent .mat-slider-thumb-label
	background-color: #ff4081

.mat-checkbox
	label.mat-checkbox-layout
		display: flex
		white-space: normal !important
		.mat-checkbox-inner-container
			margin-right: 20px

// SINGLE DROPDOWN - START

div.DivFormSingleDropdown
	.ng-select
		width: 100%
		.ng-select-container
			height: 50px
			width: 100%
			border-radius: $SPACE_TINY
			background-repeat: no-repeat
			background-position: right
			background-size: 55px
			background-color: #EFEFEF
			.ng-value-container
				flex-wrap: wrap
				width: 100%
				height: 100%
				position: relative
				align-content: center
				align-items: center
				.ng-value
					padding-right: $SPACE_TINY
					font-size: 15px
					border-radius: $SPACE_TINY
					margin-right: $SPACE_TINY
					// display: flex
					line-height: 50px
					// align-items: center
				.ng-input>input
					margin-bottom: 0px !important
					background-color: transparent !important
			.ng-clear-wrapper
				display: none
	.ng-clear-wrapper
		margin: auto $SPACE_LARGE !important
		height: $SPACE_MEDIUM
	.ng-dropdown-panel
		border-radius: $SPACE_LITTLE
		.ng-dropdown-panel-items
			min-height: 40px
			.ng-option
				box-sizing: border-box
				cursor: pointer
				display: block
				margin-bottom: -$SPACE_SMALL
				margin-left: $SPACE_LITTLE
				height: $SPACE_HUGE

//SINGLE  DROPDOWN - END

// ANGULAR MATERIAL - END

// BRINS DIGITAL - END


// NGX-MAT-TIMEPICKER START

div.DivForm, form div.DivForm fieldset
	table.ngx-mat-timepicker-table
		tbody.ngx-mat-timepicker-tbody
			td.ngx-mat-timepicker-spacer
				padding-top: $SPACE_LITTLE
			div, mat-form-field
				margin: 0px
				padding: 0px
				tr
					td
						height: 35px

// NGX-MAT-TIMEPICKER END


// SIGN IN - START

div.DivSignInFormContainer
	div.DivForm
		label.LabelAlignCenter
			display: flex
			flex-direction: column
			align-items: center
			margin-bottom: 20px !important

// SIGN IN - END