/* @charset "utf-8"; */
/* CSS Document */

// SAKINA - START

/* THEME - START */

$THEME_PRIMARY_DARK_COLOR: #004E94
$THEME_PRIMARY_DARK_RED: 0
$THEME_PRIMARY_DARK_GREEN: 78
$THEME_PRIMARY_DARK_BLUE: 148
$THEME_PRIMARY_NORMAL_COLOR: #005DA3
$THEME_PRIMARY_NORMAL_RED: 0
$THEME_PRIMARY_NORMAL_GREEN: 93
$THEME_PRIMARY_NORMAL_BLUE: 163
$THEME_PRIMARY_LIGHT_COLOR: #1476BC
$THEME_PRIMARY_LIGHT_RED: 20
$THEME_PRIMARY_LIGHT_GREEN: 118
$THEME_PRIMARY_LIGHT_BLUE: 188
$THEME_PRIMARY_SOFT_COLOR: #2876BC
$THEME_PRIMARY_SOFT_RED: 40
$THEME_PRIMARY_SOFT_GREEN: 118
$THEME_PRIMARY_SOFT_BLUE: 188
$THEME_PRIMARY_ACCENT_COLOR: #29ABE2
$THEME_PRIMARY_ACCENT_RED: 41
$THEME_PRIMARY_ACCENT_GREEN: 171
$THEME_PRIMARY_ACCENT_BLUE: 226
$THEME_PRIMARY_HIGHLIGHT_COLOR: #6BB9FF
$THEME_PRIMARY_HIGHLIGHT_RED: 107
$THEME_PRIMARY_HIGHLIGHT_GREEN: 185
$THEME_PRIMARY_HIGHLIGHT_BLUE: 255

$THEME_SECONDARY_DARK_COLOR: #F26F21
$THEME_SECONDARY_DARK_RED: 242
$THEME_SECONDARY_DARK_GREEN: 111
$THEME_SECONDARY_DARK_BLUE: 33
$THEME_SECONDARY_NORMAL_COLOR: #F7931E
$THEME_SECONDARY_NORMAL_RED: 247
$THEME_SECONDARY_NORMAL_GREEN: 147
$THEME_SECONDARY_NORMAL_BLUE: 30

$THEME_TERTIARY_DARK_COLOR: #787878
$THEME_TERTIARY_DARK_RED: 120
$THEME_TERTIARY_DARK_GREEN: 120
$THEME_TERTIARY_DARK_BLUE: 120
$THEME_TERTIARY_NORMAL_COLOR: #B4B4B4
$THEME_TERTIARY_NORMAL_RED: 180
$THEME_TERTIARY_NORMAL_GREEN: 180
$THEME_TERTIARY_NORMAL_BLUE: 180
$THEME_TERTIARY_LIGHT_COLOR: #E6E6E6
$THEME_TERTIARY_LIGHT_RED: 230
$THEME_TERTIARY_LIGHT_GREEN: 230
$THEME_TERTIARY_LIGHT_BLUE: 230
$THEME_TERTIARY_SOFT_COLOR: #FFFFFF
$THEME_TERTIARY_SOFT_RED: 255
$THEME_TERTIARY_SOFT_GREEN: 255
$THEME_TERTIARY_SOFT_BLUE: 255

$THEME_QUATERNARY_LIGHT_COLOR: #efefef
$THEME_QUATERNARY_DARK_COLOR: #666666
$THEME_QUARTERNARY_SOFT_COLOR: #e9efef

$THEME_TERTIARY_SOFT_BLUE_COLOR: #1576B3
$THEME_TERTIARY_DARK_BLUE_COLOR: #064F89
$THEME_TERTIARY_SOFT_GREY_COLOR: #999090
$THEME_TERTIARY_SOFT_ORANGE_COLOR: #F38F1E
$THEME_TERTIARY_DARK_ORANGE_COLOR: #D85526
$THEME_TERTIARY_SKY_BLUE_COLOR: #91B9CA
$THEME_TERTIARY_SEMI_WHITE_COLOR: #F2F2F2

$THEME_BRIINSURANCE_BLUE_COLOR: #0045E6
$THEME_BRIINSURANCE_ORANGE_COLOR: #FF4D00

$THEME_LABEL_FOOTER_COLOR: #D36707

/* THEME - END */

/* INDICATOR - START */

$THEME_INDICATOR_SUCCESS_DARK_COLOR: #009245
$THEME_INDICATOR_SUCCESS_DARK_RED: 0
$THEME_INDICATOR_SUCCESS_DARK_GREEN: 146
$THEME_INDICATOR_SUCCESS_DARK_BLUE: 69
$THEME_INDICATOR_SUCCESS_NORMAL_COLOR: #14A659
$THEME_INDICATOR_SUCCESS_NORMAL_RED: 20
$THEME_INDICATOR_SUCCESS_NORMAL_GREEN: 166
$THEME_INDICATOR_SUCCESS_NORMAL_BLUE: 89
$THEME_INDICATOR_WARNING_COLOR: #F15A24
$THEME_INDICATOR_WARNING_RED: 241
$THEME_INDICATOR_WARNING_GREEN: 90
$THEME_INDICATOR_WARNING_BLUE: 36
$THEME_INDICATOR_FAIL_DARK_COLOR: #C1272D
$THEME_INDICATOR_FAIL_DARK_RED: 193
$THEME_INDICATOR_FAIL_DARK_GREEN: 36
$THEME_INDICATOR_FAIL_DARK_BLUE: 45
$THEME_INDICATOR_FAIL_NORMAL_COLOR: #D53841
$THEME_INDICATOR_FAIL_NORMAL_RED: 213
$THEME_INDICATOR_FAIL_NORMAL_GREEN: 56
$THEME_INDICATOR_FAIL_NORMAL_BLUE: 65
$THEME_INDICATOR_INFORMATION_COLOR: #0071BC
$THEME_INDICATOR_INFORMATION_RED: 0
$THEME_INDICATOR_INFORMATION_GREEN: 113
$THEME_INDICATOR_INFORMATION_BLUE: 188
$THEME_INDICATOR_SEVERITY_URGENT_COLOR: #FF0000
$THEME_INDICATOR_SEVERITY_URGENT_RED: 255
$THEME_INDICATOR_SEVERITY_URGENT_GREEN: 0
$THEME_INDICATOR_SEVERITY_URGENT_BLUE: 0
$THEME_INDICATOR_SEVERITY_HIGH_COLOR: #C92D00
$THEME_INDICATOR_SEVERITY_HIGH_RED: 201
$THEME_INDICATOR_SEVERITY_HIGH_GREEN: 45
$THEME_INDICATOR_SEVERITY_HIGH_BLUE: 0
$THEME_INDICATOR_SEVERITY_MEDIUM_COLOR: #F7941D
$THEME_INDICATOR_SEVERITY_MEDIUM_RED: 247
$THEME_INDICATOR_SEVERITY_MEDIUM_GREEN: 148
$THEME_INDICATOR_SEVERITY_MEDIUM_BLUE: 29
$THEME_INDICATOR_SEVERITY_LOW_COLOR: #D8CB30
$THEME_INDICATOR_SEVERITY_LOW_RED: 216
$THEME_INDICATOR_SEVERITY_LOW_GREEN: 203
$THEME_INDICATOR_SEVERITY_LOW_BLUE: 48

/* INDICATOR - END */

/* LIST - START */

$THEME_LIST_EVEN_COLOR: #FFFFFF
$THEME_LIST_EVEN_RED: 255
$THEME_LIST_EVEN_GREEN: 255
$THEME_LIST_EVEN_BLUE: 255
$THEME_LIST_EVEN_OPACITY: 0.2
$THEME_LIST_ODD_COLOR: #FFFFFF
$THEME_LIST_ODD_RED: 255
$THEME_LIST_ODD_GREEN: 255
$THEME_LIST_ODD_BLUE: 255
$THEME_LIST_ODD_OPACITY: 0.1

/* LIST - END */

/* CONVERSATION - START */

$THEME_CONVERSATION_CUSTOMER_COLOR: #E0BB16
$THEME_CONVERSATION_CUSTOMER_RED: 224
$THEME_CONVERSATION_CUSTOMER_GREEN: 187
$THEME_CONVERSATION_CUSTOMER_BLUE: 22
$THEME_CONVERSATION_DIVISION_COLOR: #943707
$THEME_CONVERSATION_DIVISION_RED: 148
$THEME_CONVERSATION_DIVISION_GREEN: 55
$THEME_CONVERSATION_DIVISION_BLUE: 7
$THEME_CONVERSATION_PRIVATE_COLOR: #F7941D
$THEME_CONVERSATION_PRIVATE_RED: 247
$THEME_CONVERSATION_PRIVATE_GREEN: 148
$THEME_CONVERSATION_PRIVATE_BLUE: 29
$THEME_CONVERSATION_PUBLIC_COLOR: #1C75BC
$THEME_CONVERSATION_PUBLIC_RED: 28
$THEME_CONVERSATION_PUBLIC_GREEN: 117
$THEME_CONVERSATION_PUBLIC_BLUE: 188
$THEME_CONVERSATION_DISABLED: #6D6E71
$THEME_CONVERSATION_DISABLED_RED: 109
$THEME_CONVERSATION_DISABLED_GREEN: 110
$THEME_CONVERSATION_DISABLED_BLUE: 113

/* CONVERSATION - START */


/* RRNEWAL - START */

$THEME_POLICY_EXECUTIVE_COLOR: #d4af37
$THEME_POLICY_EXECUTIVE_RED: 212
$THEME_POLICY_EXECUTIVE_GREEN: 175
$THEME_POLICY_EXECUTIVE_BLUE: 55

/* RENEWAL - START */

/* BRINS-WEBSITE - START */

$THEME_PRIMARY_DARK_BLACK: #000000
$THEME_PRIMARY_PILLAR_CORPORATE: #E81E25
$THEME_PRIMARY_PILLAR_SYARIAH: #69A127
$THEME_PRIMARY_PILLAR_MIKRO: #D56707
$THEME_PRIMARY_PILLAR_RITEL: #004980
$THEME_PRIMARY_PILLAR_DIGITAL: #6D6E71

$THEME_PRIMARY_PAYMENT_BACKGROUND_TIME: #d9d9d9

$THEME_PRIMARY_TABLE_ROW_ODD: #eaeaea
$THEME_PRIMARY_TABLE_ROW_EVEN: #f9f9f9

/* BRINS-WEBSITE - END */

// SAKINA - END

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// SETUP - START

/* SEMANTIC ELEMENTS - START */

div.DivBackground
	+generateBackgroundGradientTopBottom($THEME_PRIMARY_LIGHT_COLOR, $THEME_PRIMARY_LIGHT_RED, $THEME_PRIMARY_LIGHT_GREEN, $THEME_PRIMARY_LIGHT_BLUE, $THEME_PRIMARY_DARK_COLOR, $THEME_PRIMARY_DARK_RED, $THEME_PRIMARY_DARK_GREEN, $THEME_PRIMARY_DARK_BLUE)

footer
	background-image: url($URL_SHAPE_RESOURCE + "shape_footer.svg")
	background-color: transparent
	background-position: top
	background-repeat: no-repeat
	background-size: cover

+generateInputPlaceholder($THEME_TERTIARY_NORMAL_COLOR)

/* SEMANTIC ELEMENTS - END */

/* NEUTRALIZATION - START */

h1, h2, h3, h4, h5, h6, p, mark, ol, ul, li
	background-color: transparent

/* NEUTRALIZATION - END */

// SETUP - END


// LAYOUT - START

/* GENERAL - START */

div.DivForm, div.DivFormBranch
	background-color: $THEME_TERTIARY_SOFT_COLOR
	span, p
		color: $THEME_TERTIARY_DARK_COLOR
	span.SpanActiveStatus, i.IActiveStatus
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivCard
	+generateBackgroundGradientLeftRight($THEME_PRIMARY_LIGHT_COLOR, $THEME_PRIMARY_LIGHT_RED, $THEME_PRIMARY_LIGHT_GREEN, $THEME_PRIMARY_LIGHT_BLUE, $THEME_PRIMARY_DARK_COLOR, $THEME_PRIMARY_DARK_RED, $THEME_PRIMARY_DARK_GREEN, $THEME_PRIMARY_DARK_BLUE)
	span
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivCardFinish
	+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	span
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivForm
	div#divPercentageApproval, div#divPercentageConfirmation
		background-color: $THEME_SECONDARY_DARK_COLOR
		div#divSubPercentageProgress
			background-color: $THEME_SECONDARY_NORMAL_COLOR

div.DivProfileGeneral
	div.DivProfileInfo
		div.DivProfileInfoDetails
			a, span
				color: $THEME_TERTIARY_SOFT_COLOR

/* GENERAL - END */

/* HOME - START */

div#divBodyHome
	background-color: transparent
	input#buttonNavigation
		background-image: url($URL_ICON_RESOURCE + "icon_navigation_show.svg")

/* HOME - END */

/* DASHBOARD - START */

div#divBodyHome
	input#buttonNavigation
		background-image: url($URL_ICON_RESOURCE + "icon_navigation_show.svg")

form div.DivForm fieldset, div.DivForm
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer, div.DivFormListHorizontalContainer
		div.DivListChart
			ul
				background: rgba(0,0,0,.05)
				li
					color: $THEME_TERTIARY_DARK_COLOR
					span:first-of-type
						background-color: $THEME_SECONDARY_DARK_COLOR
						color: $THEME_TERTIARY_SOFT_COLOR

div#divBodyDashboard1
	width:100%
	height:100vh
	background-repeat: no-repeat
	object-fit: cover
	// background-image: url($URL_BACKGROUND_RESOURCE + "background_home.svg")

div#divBodyDashboardBussinesPillars
	div.DivContentLayoutContent
		div.DivContentCorporatePageImage
			h1.headerPillarCorporate
				color: $THEME_PRIMARY_PILLAR_CORPORATE
			h1.headerPillarRitel
				color: $THEME_PRIMARY_PILLAR_RITEL
			h1.headerPillarMikro
				color: $THEME_PRIMARY_PILLAR_MIKRO
			h1.headerPillarDigital
				color: $THEME_PRIMARY_PILLAR_DIGITAL
			h1.headerPillarSyariah
				color: $THEME_PRIMARY_PILLAR_SYARIAH


/* DASHBOARD - END */

/* TABLE - START */

div.DivTableContainer
	ul.ListTableThumbnails, ul.ListTableTicket
		background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.1)
	ul.ListTableConversation
		li
			background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.2)
	ul.ListTableTicket
		li:nth-child(odd)
			background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.2)
		span.TicketLow
			background-color: green
		span.TicketMedium
			background-color: orange
		span.TicketHigh
			background-color: red
	table
		thead
			tr
				th
					background-color: $THEME_TERTIARY_SOFT_COLOR
		tbody
			tr:nth-child(even)
				td
					background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.1)
			tr:nth-child(odd)
				td
					background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.2)


form.FormInsertConversation, form.FormUploadFile
	table.TableListDetails
		color: $THEME_TERTIARY_DARK_COLOR


/* TABLE - END */

/* LOADING - START */

div#divCurtainLoading
	background-color: rgba(0, 0, 0, 0.6)
	div#divLoadingBar
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	span
		color: $THEME_TERTIARY_SOFT_COLOR

/* LOADING - END */

/* NOTIFICATION - START */

div#divNotificationInstall, div#divNotificationInstallList
	+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	color: $THEME_TERTIARY_SOFT_COLOR
	input[type=button]
		color: $THEME_TERTIARY_SOFT_COLOR
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	input[type=button]#buttonNotificationClose
		background-image: url($URL_ICON_RESOURCE + "icon_X.svg")

/* NOTIFICATION - END */

// LAYOUT - END


// LABEL - START

/* GENERAL - START */

form
	div.DivForm
		h3
			color: $THEME_SECONDARY_DARK_COLOR
		fieldset
			legend
				color: $THEME_SECONDARY_NORMAL_COLOR
			label
				color: $THEME_TERTIARY_DARK_COLOR
	label.LabelAttachment
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	label.LabelAttachmentDisable
		background: $THEME_CONVERSATION_DISABLED

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	header.HeaderGeneral
		h2, p
			color: $THEME_TERTIARY_SOFT_COLOR
	div.DivForm
		h3, h4
			color: $THEME_SECONDARY_DARK_COLOR
		label, .legend-title
			color: $THEME_TERTIARY_DARK_COLOR
	div.DivCenterContainer
		div.DivForm
			div.DivSubHeaderHeadline
				label
					color: $THEME_SECONDARY_NORMAL_COLOR
				h2
					color: $THEME_SECONDARY_DARK_COLOR
			div.DivHeaderStatus
				span
					color: $THEME_TERTIARY_SOFT_COLOR
				span.SpanSelectStatus
					background-color: $THEME_SECONDARY_DARK_COLOR
				span.Urgent
					background-color: $THEME_INDICATOR_SEVERITY_URGENT_COLOR
				span.SeverityHigh
					background-color: $THEME_INDICATOR_SEVERITY_HIGH_COLOR
				span.SeverityMedium
					background-color: $THEME_INDICATOR_SEVERITY_MEDIUM_COLOR
				span.SeverityLow
					background-color: $THEME_INDICATOR_SEVERITY_LOW_COLOR
			div.DivFormHorizontalContainer
				div.DivHeaderInfo
					dl
						dt, dd
							color: $THEME_TERTIARY_DARK_COLOR
				div.DivHeaderList
					ul
						li
							background-color: $THEME_TERTIARY_LIGHT_COLOR
							span
								color: $THEME_TERTIARY_DARK_COLOR

div.DivForm, form div.DivForm fieldset
	textarea#spanIncidentNote
		background-color: white
	textarea.TextareaDisable
		background-color: $THEME_TERTIARY_SOFT_COLOR


/* GENERAL - END */

/* NAVIGATION - START */

nav
	color: $THEME_TERTIARY_SOFT_COLOR
	figure
		div, img#imageProfilePicture, span#spanProfileNameInitial
			border-color: $THEME_TERTIARY_SOFT_COLOR
		div
			background-color: transparent
			img#imageProfilePicture
				background-color: transparent
			span#spanProfileNameInitial
				background-color: $THEME_PRIMARY_HIGHLIGHT_COLOR
	div
		a
			color: $THEME_PRIMARY_DARK_BLACK

/* NAVIGATION - END */

/* SIGN IN - START */

div#divBodySignIn
	header#headerSignIn
		h1, span
			color: $THEME_SECONDARY_DARK_COLOR
	div.DivEmailNotRegistered
		p.PEmailNotRegistered, a.AContactUs, p.PVersion
			color: $THEME_TERTIARY_SOFT_COLOR

div.DivSignInContainer
	div.DivSignInBackground
		background-image: url($URL_PHOTO_RESOURCE + "/photo_signin.png")
	div.DivSignInFormContainer
		form
			fieldset
				div.DivInputHorizontalContainer
					input[type=email]
						background-image: url($URL_ICON_RESOURCE + "icon_mail.svg")
					div.DivEmailIcon
						background-image: url($URL_ICON_RESOURCE + "icon_mail.svg")

/* SIGN IN - END */

/* DASHBOARD - START */

div#divMainDashboard, div#divBodyDashboard
	div.DivHighlight
		span.SpanHighlight
			background-color: $THEME_SECONDARY_DARK_COLOR
			color: $THEME_TERTIARY_SOFT_COLOR
	section.SectionMain
		img.ImgShapeMain1
			background-image: url($URL_SHAPE_RESOURCE + "shape_header_kiri_landing.svg")
			width: 67%
			height: 100vh
			background-size: contain
			background-repeat: no-repeat
			background-position-y: bottom
			position: absolute
			z-index: 0
			border: none
			outline: none
		img.ImgShapeMain2
			background-image: url($URL_SHAPE_RESOURCE + "shape_header_kanan_landing.svg")
			width: 100%
			height: 75%
			background-size: contain
			background-repeat: no-repeat
			background-position-x: right
			position: absolute
			right: 0
			bottom: 0
			z-index: 0
			border: none
			outline: none
	section#sectionHeaderProduct
		img.ImgShapeMain1
			background-image: url($URL_SHAPE_RESOURCE + "shape_header_kiri.svg")
			width: 65%
			height: 100%
			background-size: contain
			background-repeat: no-repeat
			background-position-y: bottom
			position: absolute
			z-index: 0
			border: none
			outline: none
			bottom: 0
			left: 0
		img.ImgShapeMain2
			background-image: url($URL_SHAPE_RESOURCE + "shape_header_kanan.svg")
			width: 100%
			height: 70%
			background-size: contain
			background-repeat: no-repeat
			background-position-x: right
			position: absolute
			right: 0
			bottom: 0
			z-index: 0
			border: none
			outline: none

	ol.OrderedNumber
		li
			color: $THEME_QUATERNARY_DARK_COLOR
	div.DivContentPartners
		h2, p, span
			color: $THEME_TERTIARY_SOFT_COLOR
	ol.OrderedSoft
		li
			color: $THEME_TERTIARY_SOFT_COLOR
/* DASHBOARD - END */

/* TABLE - START */

div.DivTableContainer, div.DivListContainer
	th
		color: $THEME_TERTIARY_SOFT_GREY_COLOR
	td
		color: $THEME_PRIMARY_DARK_BLACK
	span.SpanTicketStatus
		background-color: $THEME_PRIMARY_HIGHLIGHT_COLOR
	table.TableListDetailsAttachment
		label.LabelAttachment
			+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
		tr:nth-child(odd) td
			background-color: $THEME_PRIMARY_TABLE_ROW_ODD
		tr:nth-child(even) td
			background-color: $THEME_PRIMARY_TABLE_ROW_EVEN
/* TABLE - END */

/* DYNAMIC CONTAINER - START */

div.DivListControlButton
	label
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivTransparantContainer, div.DivListTransparantContainer
	background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.2)

div.DivNavigationControlButton
	input[type=radio] + label
		color: $THEME_TERTIARY_SOFT_COLOR
		+generateBackgroundGradientLeftRight($THEME_PRIMARY_LIGHT_COLOR, $THEME_PRIMARY_LIGHT_RED, $THEME_PRIMARY_LIGHT_GREEN, $THEME_PRIMARY_LIGHT_BLUE, $THEME_PRIMARY_DARK_COLOR, $THEME_PRIMARY_DARK_RED, $THEME_PRIMARY_DARK_GREEN, $THEME_PRIMARY_DARK_BLUE)
	input[type=radio]:checked + label
		color: $THEME_TERTIARY_SOFT_COLOR
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)

div.DivDynamicContainer
	form.FormInsertConversation
		div.DivConversationGeneral
			div.DivConversationContent
				div.DivAttachmentContainer
					background-color: $THEME_TERTIARY_LIGHT_COLOR

div.DivDynamicContainer
	// div.DivSubDynamicContainer
	div.DivFormListSurveyModel, div.DivFormListSurveyThirdPartyLiability
		label.LabelClaimLetterStatus, label.LabelClaimSurveyStatus
			a
				color: $THEME_TERTIARY_LIGHT_COLOR

.ProfileDivision
	background-color: $THEME_CONVERSATION_DIVISION_COLOR

.ProfileCustomer
	background-color: $THEME_CONVERSATION_CUSTOMER_COLOR

.ProfileBranch
	background-color: $THEME_INDICATOR_SUCCESS_NORMAL_COLOR

.PrivacyPrivate
	background-color: $THEME_CONVERSATION_PRIVATE_COLOR

.PrivacyPublic
	background-color: $THEME_CONVERSATION_PUBLIC_COLOR

.StatusActive
	background-color: $THEME_PRIMARY_LIGHT_COLOR

.StatusNotActive
	background-color: $THEME_INDICATOR_SEVERITY_HIGH_COLOR

.Verified
	background-color:  $THEME_INDICATOR_SUCCESS_DARK_COLOR

.NotVerified
	background-color: $THEME_INDICATOR_FAIL_DARK_COLOR

.Pending
	background-color: $THEME_SECONDARY_DARK_COLOR

.Surveyed
	background-color: $THEME_PRIMARY_SOFT_COLOR

/* DYNAMIC CONTAINER - END */

// LABEL - END


// INPUT -START

/* GENERAL - START */

input, select, textarea
	color: $THEME_PRIMARY_DARK_BLACK


div.DivRadioButtonContainer, div.DivRadioButtonContainerVertical
	div.DivRadioButton
		background-color: transparent
		border-color: $THEME_TERTIARY_DARK_COLOR
		label
			background-color: $THEME_SECONDARY_DARK_COLOR

div.DivCheckBoxContainer
	div.DivCheckBox
		background-color: transparent
		border-color: $THEME_TERTIARY_DARK_COLOR
		label
			background-color: $THEME_SECONDARY_DARK_COLOR

div.DivRoundButtonContainer
	label
		color: $THEME_SECONDARY_DARK_COLOR !important

select
	background-image: url($URL_INPUT_RESOURCE + "icon_dropdown.svg")

form
	div.DivForm
		fieldset
			div.DivInputHorizontalContainer
				input.InputPrice
					background-color: $THEME_QUATERNARY_LIGHT_COLOR
img.ImageCopy
	background-image: url($URL_ICON_RESOURCE + "icon_copy_new.svg")

/* GENERAL - END */

/* THIRD PARTY - START */

.mat-form-field-appearance-fill .mat-form-field-flex
	color: $THEME_TERTIARY_DARK_COLOR
	background-color: $THEME_TERTIARY_SOFT_COLOR
	border: 1px solid $THEME_TERTIARY_NORMAL_COLOR

.mat-focused
	color: $THEME_TERTIARY_DARK_COLOR

.ng-select
	background-repeat: no-repeat
	background-position: right
	background-size: $SPACE_HUGE

.ng-dropdown-panel
	background-color: $THEME_TERTIARY_SOFT_COLOR

.mat-form-field-suffix
	.mat-datepicker-toggle-default-icon
		color: $THEME_TERTIARY_SOFT_ORANGE_COLOR

// DROPDOWN AND FILTER - START

div.DivFormMultipleDropdown, div.DivFormSingleDropdown
	.NgSelectAsignee > .ng-select-container
		background-image: url($URL_INPUT_RESOURCE + "icon_dropdown.svg")
	.NgSelectYear > .ng-select-container
		background-image: url($URL_ICON_RESOURCE + "icon_date.svg")
		background-size: 25px
		background-position-x: 98%
	.ng-select-container
		background-repeat: no-repeat
		background-position: right
		background-size: 36px
		background-color: $THEME_TERTIARY_SOFT_COLOR
		.ng-placeholder
			color: $THEME_TERTIARY_DARK_COLOR
	.ng-dropdown-panel
		background-color: $THEME_TERTIARY_SOFT_COLOR
div.DivFormSingleDropdown
	.ng-value
		span
			color: #000 !important
div.DivFormMultipleDropdown
	.ng-value
		background-color: $THEME_TERTIARY_SOFT_COLOR
		.ng-value-icon.left
			border: 1px solid $THEME_TERTIARY_DARK_COLOR

// DROPDOWN AND FILTER - END

/* THIRD PARTY - END */


/* NAVIGATION - START */

nav
	li:hover, li.ListNavigationMenuActive, li.ListItemSubNavigation
		color: #F38F1E

/* NAVIGATION - END */

/* SIGN IN - START */

/* SIGN IN - END */

// INPUT - END


// LINK - START

/* GENERAL - START */

a.ADownload
	color: $THEME_TERTIARY_SOFT_COLOR

form
	input[type=button]
		color: $THEME_TERTIARY_SOFT_COLOR
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	input[type=button].ButtonPositive
		+generateBackgroundGradientLeftRight($THEME_INDICATOR_SUCCESS_NORMAL_COLOR, $THEME_INDICATOR_SUCCESS_NORMAL_RED, $THEME_INDICATOR_SUCCESS_NORMAL_GREEN, $THEME_INDICATOR_SUCCESS_NORMAL_BLUE, $THEME_INDICATOR_SUCCESS_DARK_COLOR, $THEME_INDICATOR_SUCCESS_DARK_RED, $THEME_INDICATOR_SUCCESS_DARK_GREEN, $THEME_INDICATOR_SUCCESS_DARK_BLUE)
	input[type=button].ButtonNegative
		+generateBackgroundGradientLeftRight($THEME_INDICATOR_FAIL_NORMAL_COLOR, $THEME_INDICATOR_FAIL_NORMAL_RED, $THEME_INDICATOR_FAIL_NORMAL_GREEN, $THEME_INDICATOR_FAIL_NORMAL_BLUE, $THEME_INDICATOR_FAIL_DARK_COLOR, $THEME_INDICATOR_FAIL_DARK_RED, $THEME_INDICATOR_FAIL_DARK_GREEN, $THEME_INDICATOR_FAIL_DARK_BLUE)

input[type=button].ButtonAttachment
	background-color: transparent
	background-image: url($URL_ICON_RESOURCE + "icon_attach.svg")

input[type=button].ButtonPDF
	background-image: url($URL_ICON_RESOURCE + "icon_file_pdf.svg")

input[type=button].ButtonExcel
	background-image: url($URL_ICON_RESOURCE + "icon_file_excel.svg")

input[type=button].ButtonCopy
	background-color: transparent
	background-image: url($URL_ICON_RESOURCE + "icon_copy.svg")

input[type=button].ButtonRemove
	background-image: url($URL_ICON_RESOURCE + "icon_remove.svg")

input[type=button].ButtonRemovefile
	background-image: url($URL_ICON_RESOURCE + "icon_remove.svg")

input[type=button].ButtonDownload
	background-image: url($URL_ICON_RESOURCE + "icon_download_brins.svg")

input[type=button].ButtonAddTicket
	background-image: url($URL_ICON_RESOURCE + "icon_customer_care_white.svg")
	background-color: $THEME_SECONDARY_NORMAL_COLOR

input[type=button].ButtonShare
	background-image: url($URL_ICON_RESOURCE + "icon_share.svg")

input[type=button].ButtonAddPolicyRenewal
	background-image: url($URL_ICON_RESOURCE + "icon_search.svg")

input[type=button].ButtonAddPolicyOutstanding
	background-image: url($URL_ICON_RESOURCE + "icon_search.svg")


input[type=button]:disabled
	+generateBackgroundGradientLeftRight($THEME_TERTIARY_DARK_COLOR, $THEME_TERTIARY_DARK_RED, $THEME_TERTIARY_DARK_GREEN, $THEME_TERTIARY_DARK_BLUE, $THEME_TERTIARY_NORMAL_COLOR, $THEME_TERTIARY_NORMAL_RED, $THEME_TERTIARY_NORMAL_GREEN, $THEME_TERTIARY_NORMAL_BLUE)

/* GENERAL - END */

/* HOME - START */

input[type=button]#buttonNavigation
	background-color: transparent

/* HOME - END */

/* PRODUCT INFORMATION - START */

input[type=button]#buttonCalculator
	background-color: transparent
	background-image: url($URL_ICON_RESOURCE + "icon_calculate.svg")

/* PRODUCT INFORMATION - END */

/* TABLE - START */

div.DivTableControl, div.DivTableContainer, div.DivTableListControl
	div.DivButtonFirst.Enable, div.DivButtonPrevious.Enable, div.DivButton
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	div.DivButtonNext.Enable, div.DivButtonLast.Enable
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE, $THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE)
	div.DivButtonFirst.Disable, div.DivButtonPrevious.Disable, div.DivButtonFirst, div.DivButtonPrevious
		+generateBackgroundGradientLeftRight($THEME_TERTIARY_NORMAL_COLOR, $THEME_TERTIARY_NORMAL_RED, $THEME_TERTIARY_NORMAL_GREEN, $THEME_TERTIARY_NORMAL_BLUE, $THEME_TERTIARY_DARK_COLOR, $THEME_TERTIARY_DARK_RED, $THEME_TERTIARY_DARK_GREEN, $THEME_TERTIARY_DARK_BLUE)
	div.DivButtonNext.Disable, div.DivButtonLast.Disable, div.DivButtonNext, div.DivButtonLast
		+generateBackgroundGradientLeftRight($THEME_TERTIARY_DARK_COLOR, $THEME_TERTIARY_DARK_RED, $THEME_TERTIARY_DARK_GREEN, $THEME_TERTIARY_DARK_BLUE, $THEME_TERTIARY_NORMAL_COLOR, $THEME_TERTIARY_NORMAL_RED, $THEME_TERTIARY_NORMAL_GREEN, $THEME_TERTIARY_NORMAL_BLUE)
	div.DivButtonFirst input[type=button]
		background-image: url($URL_ICON_RESOURCE + "icon_first.svg")
	div.DivButtonPrevious input[type=button]
		background-image: url($URL_ICON_RESOURCE + "icon_previous.svg")
	div.DivButtonNext input[type=button]
		background-image: url($URL_ICON_RESOURCE + "icon_next.svg")
	div.DivButtonLast input[type=button]
		background-image: url($URL_ICON_RESOURCE + "icon_last.svg")
	div.ButtonForward:before, div.ButtonForward:hover:before, div.ButtonForwardInstall:before, div.ButtonForwardInstall:hover:before
		color: $THEME_TERTIARY_SOFT_COLOR
		background-color: $THEME_SECONDARY_DARK_COLOR
	input[type=button]
		background-color: transparent
	span.SpanTablePage
		color: $THEME_TERTIARY_SOFT_COLOR
	label
		color: $THEME_TERTIARY_SOFT_GREY_COLOR
	select
		+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
div.DivTableControl
	select
		background-image: none !important

table.TableBox
	+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))


/* TABLE - END */

// LINK - END


// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

#divMainDashboard
	div.DivFormCardInside
		div.DivFormBranch
			input[type=button]
				background-color: $THEME_SECONDARY_DARK_COLOR
			div.DivBranchCardInformation
				img
					background-color: $THEME_SECONDARY_DARK_COLOR 
			p
				color: $THEME_TERTIARY_SOFT_COLOR
			
			label
				color: $THEME_TERTIARY_DARK_COLOR


// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL END //

/* RENEWAL - START */

div.DivCardPolicy
	background-color: $THEME_TERTIARY_SOFT_COLOR
	span, h2
		color: $THEME_SECONDARY_NORMAL_COLOR

div.DivCardInformationPolicy
	+generateBackgroundGradientLeftRight($THEME_PRIMARY_LIGHT_COLOR, $THEME_PRIMARY_LIGHT_RED, $THEME_PRIMARY_LIGHT_GREEN, $THEME_PRIMARY_LIGHT_BLUE, $THEME_PRIMARY_DARK_COLOR, $THEME_PRIMARY_DARK_RED, $THEME_PRIMARY_DARK_GREEN, $THEME_PRIMARY_DARK_BLUE)

div.DivCardInformationPolicy
		span.spanStatusPolicyClosed
			background-color: $THEME_INDICATOR_SEVERITY_URGENT_COLOR
		span.spanStatusPolicyInforce
			background-color: $THEME_INDICATOR_SUCCESS_NORMAL_COLOR
		span.spanStatusPolicyTemporary
			background-color: $THEME_PRIMARY_HIGHLIGHT_COLOR
		span.spanStatusPolicyWaiting
			+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
		span.spanStatusExecutive
			+generateBackgroundGradientLeftRight($THEME_POLICY_EXECUTIVE_COLOR, $THEME_POLICY_EXECUTIVE_RED, $THEME_POLICY_EXECUTIVE_GREEN, $THEME_POLICY_EXECUTIVE_BLUE, $THEME_INDICATOR_SEVERITY_LOW_COLOR, $THEME_INDICATOR_SEVERITY_LOW_RED, $THEME_INDICATOR_SEVERITY_LOW_GREEN, $THEME_INDICATOR_SEVERITY_LOW_BLUE)

/* RENEWAL - STOP */


button.ButtonSubmit
	+backgroundGradientLeftRight($THEME_TERTIARY_SOFT_ORANGE_COLOR, $THEME_TERTIARY_DARK_ORANGE_COLOR)

div#divMainDashboard
	p
		color: $THEME_QUATERNARY_DARK_COLOR
	.FontSoftColor
		color: $THEME_TERTIARY_SOFT_COLOR !important
	.FontSoftDarkColor
		color: $THEME_TERTIARY_DARK_COLOR !important
	.FontDarkColor
		color: $THEME_PRIMARY_DARK_BLACK !important
	.FontTitleColor
		color: $THEME_QUATERNARY_DARK_COLOR !important
	ul.UlTabContainer
		background-color: $THEME_TERTIARY_LIGHT_COLOR
		li.ListTab
			background-color: $THEME_TERTIARY_LIGHT_COLOR
			color: $THEME_PRIMARY_DARK_BLACK
		li.ListActive
			background-color: $THEME_TERTIARY_DARK_COLOR
			color: $THEME_TERTIARY_SOFT_COLOR
	span.SpanPaymentLight, label.LabelPromoLight
		color: $THEME_BRIINSURANCE_ORANGE_COLOR
	div.DivPaymentExpired
		span.SpanWrapper
			background-color: $THEME_PRIMARY_PAYMENT_BACKGROUND_TIME
			color: $THEME_PRIMARY_DARK_BLACK
	div.DivFormHorizontalContainer
		label.LabelPremiumTotalAMount, span.SpanPremiumTotalAMount
			color: $THEME_TERTIARY_SOFT_ORANGE_COLOR
	div#divBodyTabAbout
		div.DivContentTabAbout
			background-color: $THEME_QUATERNARY_LIGHT_COLOR
			button
				background-color: $THEME_QUATERNARY_LIGHT_COLOR
				color: $THEME_PRIMARY_DARK_BLACK
			button:hover, button:active, button:focus, button.ButtonActive
				background-color: $THEME_TERTIARY_DARK_COLOR
				color: $THEME_TERTIARY_SOFT_COLOR
	div#divContentCart
		div.DivCartList
			div.DivCartOrderDetail, div.DivCartOrderProductList
				label
					color: $THEME_TERTIARY_DARK_COLOR
				h3
					color: $THEME_TERTIARY_DARK_BLUE_COLOR
				h5
					color: $THEME_BRIINSURANCE_ORANGE_COLOR
				p
					margin-bottom: 0px
			div.DivCartOrderProductList
				label, p
					margin: 0px auto
	div.DivContentCopy
		background: $THEME_PRIMARY_PAYMENT_BACKGROUND_TIME
		h2
			color: $THEME_BRIINSURANCE_ORANGE_COLOR
	div.DivTitle2Colour
		h2:nth-child(1)
			color: $THEME_TERTIARY_DARK_BLUE_COLOR !important
		h2:nth-child(2)
			color: $THEME_BRIINSURANCE_ORANGE_COLOR !important

div#divBodyFooterContainer
	div.DivFooterInfoPage
		div.DivFooterVerticalContainer, div.DivFooterHorizontalContainer
			label
				color: $THEME_LABEL_FOOTER_COLOR
			input
				background-color: #f0f0f0
				+generateInputPlaceholder($THEME_LABEL_FOOTER_COLOR)
		p, a
			color: $THEME_QUATERNARY_DARK_COLOR
		div.DivFooterCopyright
			p
				color: $THEME_TERTIARY_DARK_BLUE_COLOR
			hr
				border: 1px solid $THEME_TERTIARY_DARK_BLUE_COLOR
.ButtonSlideDetail
	+backgroundGradientLeftRight($THEME_TERTIARY_SOFT_BLUE_COLOR, $THEME_TERTIARY_DARK_BLUE_COLOR)

div.DivProductPackage
	div.DivContentBoxActive
		box-shadow: 0 0 0 5px $THEME_TERTIARY_SOFT_ORANGE_COLOR !important

hr.HorizontalRule
	background: $THEME_TERTIARY_NORMAL_COLOR

section.SectionSecondary
	div.DivSiteContainer
		a.ADetail
			color: $THEME_SECONDARY_DARK_COLOR
		h1, h2, h3
			color: $THEME_PRIMARY_PILLAR_DIGITAL
	button.ButtonMore
		border: solid 7px $THEME_BRIINSURANCE_ORANGE_COLOR
		label
			color: $THEME_BRIINSURANCE_ORANGE_COLOR